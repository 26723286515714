import React, { Fragment, useEffect, useState } from 'react'
import AdminHeader from '../../component/AdminHeader'
import AdminSidebar from '../../component/AdminSidebar'
import Loader from '../../component/Loader'
import adminStore from '../../store/AdminStore'
import ReactDatatable from "@mkikets/react-datatable";
import { toJS } from 'mobx'
import { BASE_FILE_URL } from '../../api/config'
import { observer } from 'mobx-react'
import { text } from '@fortawesome/fontawesome-svg-core'
import { useNavigate } from 'react-router-dom'
import swal from "sweetalert";
import Select from 'react-select'


const ShowTopics = observer(() => {
    const [videoData, setVideoData] = useState([]);
    const [courseData, setCourseData] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);

    useEffect(() => {
      adminStore?.getCourses();
  }, [])

    useEffect(() => {
        adminStore?.getvideos();
    }, [])

    const navigate = useNavigate();

    useEffect(() => {
        setVideoData(toJS(adminStore?.data?.topics))
    }, [adminStore?.data?.topics])

    useEffect(() => {
        setCourseData(toJS(adminStore?.data?.courses))
    }, [adminStore?.data?.courses])
    const columns = [
        {
            key: "#",
            text: "S No.",
            align: "left",
            sortable: true,
            cell: (record, ind) => <span>{ind + 1}</span>,
          },
      
      
        {
            key: "course_name",
            text: "Project name",
            align: "left",
            sortable: true,
        },
        {
            key: "video_title",
            text: "Video Title",
            align: "left",
            sortable: true,
        },
        {
            key: "video_url",
            text: "URL",
            align: "left",
            sortable: true,
        },
        {
            key: "thumbnail",
            text: "Thumbnail",
            align: "left",
            sortable: true,
            cell: (record) =>
                record.thumbnail ? (
                    <img
                        src={(BASE_FILE_URL + "uploads/video_thumbnail/" + record.thumbnail)}
                        alt="Thumbnail"
                        style={{ width: "50px", height: "50px", objectFit: "cover" }}
                        onLoad={(e) => URL.revokeObjectURL(e?.target?.src)}
                    />
                ) : (
                    <span>No Image</span>
                ),
        },
        {
            key: 'action',
            text: "Action",
            align: "left",
            shortable: true,
            cell: (record) => (
                <Fragment>
                    <button
                        className="btn btn-primary btn-sm mb-2"
                        onClick={() => handleEditTopic(record)}
                        style={{ marginRight: "5px" ,width: "70px" }}
                    >
                        <i className="fa-solid fa-pen mx-2"></i>
                        Edit
                    </button>
                    <button
                        className="btn btn-danger btn-sm mb-2"
                        onClick={() => handleDeleteTopic(record)}
                        style={{ marginRight: "5px" , width: "70px" }}
                    >
                        <i className="fa-solid fa-trash mx-2"></i>
                        Delete
                    </button>
                </Fragment>
            )
        }
    ]

    const handleChange = (selected) => {
      setSelectedOption(selected);
  
      if (selected && selected.value) {
          const filteredVideos = toJS(adminStore?.data?.topics).filter(
              (video) => video.course_id === selected.value
          );
          setVideoData(filteredVideos);
      } else {
          setVideoData(toJS(adminStore?.data?.topics));
      }
  };

    const options = Array.isArray(courseData)
    ? courseData.map((course) => ({
      value: course?.course_id,
      label: course?.course_name,
    }))
    : [];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Projects",
        no_data_text: "No Projects found!",
        button: {
            excel: true,
        },
    };

    const handleEditTopic = (record) =>{
        navigate("/admin/edit-topic", { state: record });
    }

    const navigationCallBackDelete = () => {
        adminStore.getvideos();
      }

    const handleDeleteTopic = (record) => {
        console.log(record,"record")
        
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this video!",
          icon: "warning",
          buttons: {
            cancel: {
              text: "No",
              value: null,
              visible: true,
              className: "btn btn-secondary",
              closeModal: true,
            },
            confirm: {
              text: "Yes",
              value: true,
              visible: true,
              className: "btn btn-danger",
              closeModal: true,
            },
          },
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            adminStore?.deleteTopic(record?.video_id, navigationCallBackDelete)
              .then(() => {
                swal("Deleted!", "The Video has been deleted successfully.", "success");
              })
              .catch((error) => {
                console.error("Error deleting Video:", error);
                swal("Error!", "Failed to delete the Video. Please try again.", "error");
              });
          } else {
            swal("Cancelled", "The Video is not deleted", "info");
          }
        });
      };


    return (
        <div>
            <AdminHeader />
            <div style={{ display: "flex", flexDirection: "row", height: "92.2vh", backgroundColor:"white" }}>
                <AdminSidebar />
                <div className="flex-grow-1" style={{
            flex: 1,
            overflow: "auto",
            padding: "30px",
          }}>
          <div className="container my-2">
            <div className='d-flex justify-content-between'>
            <h2 className=" mb-4">Topics</h2>
            <div>
            <p className="text-center fs-5 ">Select Project</p>
            <Select
                options={options}
                value={selectedOption}
                onChange={handleChange}
                placeholder="Select Project"
                styles={{
                  container: (base) => ({ ...base, width: "200px", marginTop: "10px" }),
                }}
                isMulti
              />
            </div>
            </div>
           
            <div className="table-responsive mt-2">
              {/* Check if data is passed and columns are properly defined */}
              <ReactDatatable
                    config={config}
                    records={videoData}
                    columns={columns}
                />
            </div>
          </div>
        </div>

            </div>
        </div>
    )
})

export default ShowTopics