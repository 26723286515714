import React, { Fragment, useEffect, useState } from "react";
import { Observer, observer } from "mobx-react";
import adminStore from "../../store/AdminStore";
import AdminSidebar from "../../component/AdminSidebar";
import AdminHeader from "../../component/AdminHeader";
import { Button, Card } from "react-bootstrap";
import ReactDatatable from "@mkikets/react-datatable";
import { observe, toJS } from "mobx";
import Select from 'react-select';
import swal from "sweetalert";

import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../component/Loader";

const QuizResult = observer(() => {
  const [quizData, setQuizData] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedOptionQuiz, setSelectedOptionQuiz] = useState({});
  const [courseData, setCourseData] = useState({});
  const [quizId, setQuizId] = useState([]);

  useEffect(() => {
    adminStore?.getCourses();
  }, [])

  useEffect(() => {
    setQuizId(toJS(adminStore?.data?.allQuiz))
  }, [adminStore?.data?.allQuiz])

  useEffect(() => {
    setCourseData(toJS(adminStore?.data?.courses))
  }, [adminStore?.data?.courses])

  useEffect(() => {
    if (selectedOption?.value) {
      adminStore.getAllQuizById(selectedOption?.value);
    }
  }, [selectedOption?.value]);

  useEffect(() => {
    setQuizData(toJS(adminStore.data.quizes?.questions));
  }, [adminStore.data.quizes?.questions]);


  const navigationCallBackDelete = () => {
    adminStore.getQuizAdmin(selectedOptionQuiz?.value);
  }

  const handleEditQuiz = (record) => {
    navigate("/admin/edit-quiz", { state: record });
  }

  const handleDeletQuiz = (record) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Quiz!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "btn btn-secondary",
          closeModal: true,
        },
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "btn btn-danger",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        adminStore?.deleteQuiz(record?.question_id, navigationCallBackDelete)
          .then(() => {
            swal("Deleted!", "The Quiz has been deleted successfully.", "success");
          })
          .catch((error) => {
            console.error("Error deleting Quiz:", error);
            swal("Error!", "Failed to delete the Quiz. Please try again.", "error");
          });
      } else {
        swal("Cancelled", "The Quiz is not deleted", "info");
      }
    });
  };


  const columns = [
    {
      key: "#",
      text: "S No.",
      align: "left",
      sortable: true,
      cell: (record, ind) => (
        <span>{ind + 1}</span>
      )
    },
    {
      key: "question_text",
      text: "Question",
      align: "left",
      sortable: true,
    },

    // {
    //   key: "course_description",
    //   text: "Project Description",
    //   align: "left",
    //   sortable: true,
    // },
    // {
    //   key: "questions",
    //   title: "Question",
    //   dataIndex: "question_text",
    //   align: "left",
    //   sorter: (a, b) => a.question_text.localeCompare(b.question_text), // Sorting by question text
    // },
    {
      key: "answers",
      title: "Answers",
      cell: (text) => (
        <div>
          {text?.answers?.map((answer) => (
            <div key={answer.answer_id} style={{ color: answer.is_correct === 1 ? "green" : "black", fontWeight: "500", }}>
              <input
                type="radio"
                checked={answer.is_correct === 1}
                disabled
                style={{ display: "none" }}

              />
              <span
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor: answer.is_correct === 1 ? "green" : "white",
                  display: "inline-block",
                  marginRight: "8px",
                }}
              ></span>
              {answer.answer_text}
            </div>
          ))}
        </div>
      ),
    },
    {
      key: 'action',
      text: "Action",
      align: "left",
      shortable: true,
      cell: (record) => (
        <Fragment>
          <button
            className="btn btn-primary btn-sm mb-2"
            onClick={() => handleEditQuiz(record)}
            style={{ marginRight: "5px", width: "70px" }}
          >
            <i className="fa-solid fa-pen mx-2"></i>
            Edit
          </button>
          <button
            className="btn btn-danger btn-sm mb-2"
            onClick={() => handleDeletQuiz(record)}
            style={{ marginRight: "5px", width: "70px" }}
          >
            <i className="fa-solid fa-trash mx-2"></i>
            Delete
          </button>
        </Fragment>
      )
    },



    // {
    //   key: "course_thumbnail",
    //   text: "Thumbnail",
    //   align: "left",
    //   sortable: false,
    //   cell: (record) => (
    //     record.course_thumbnail instanceof File ? (
    //       <img
    //         src={URL.createObjectURL(record.course_thumbnail)}
    //         alt="Thumbnail"
    //         style={{ width: "50px", height: "50px", objectFit: "cover" }}
    //         onLoad={(e) => URL.revokeObjectURL(e?.target?.src)}
    //       />
    //     ) : (
    //       <span>No Image</span>
    //     )
    //   ),
    // },

  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Projects",
    no_data_text: "No Projects found!",
    button: {
      excel: true,
    },
  };

  const navigate = useNavigate();
  const handleChange = (selected) => {
    setSelectedOption(selected);
    setSelectedOptionQuiz(null);
    setQuizData([]);
  };
  const handleChangeQuiz = async (selected) => {
    setSelectedOptionQuiz(selected);
    if (selected?.value) {
      await adminStore.getQuizAdmin(selected?.value)
    } else {
      setQuizData([]);
    }
  };


  const options = Array.isArray(courseData)
    ? courseData.map((course) => ({
      value: course?.course_id,
      label: course?.course_name,
    }))
    : [];

  const optionsQuiz = Array.isArray(quizId)
    ? quizId.map((quiz) => ({
      value: quiz?.quiz_id,
      label: quiz?.quiz_name,
    }))
    : [];

  return (
    <div>
      <AdminHeader />
      <div style={{ display: 'flex', flexDirection: 'row', height: '92.2vh', backgroundColor:"white" }}>
        <AdminSidebar />
        <div style={{ flex: 1, overflow: 'auto', backgroundColor: "#fff", padding: "30px" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <h2 style={{ textAlign: 'center' }}>Question List</h2>
            <div className="d-flex gap-4">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div>
              <p className="text-center fs-5 ">Select Project</p>
              <Select
                options={options}
                // value={selectedOption}
                value={selectedOption?.value ? selectedOption : null} // Use null if no selection
                onChange={handleChange}
                placeholder="Select Project"
                styles={{
                  container: (base) => ({ ...base, width: "200px", marginTop: "10px", border: "1px solid black" }),
                }}
                isMulti
              />
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div>
              <p className="text-center fs-5 ">Select Quiz</p>
              <Select
                options={optionsQuiz}
                // value={selectedOptionQuiz}
                value={selectedOptionQuiz?.value ? selectedOptionQuiz : null} // Use null if no selection
                onChange={handleChangeQuiz}
                placeholder="Select Quiz"
                styles={{
                  container: (base) => ({
                    ...base,
                    width: "200px",
                    marginTop: "10px",
                    border: "1px solid black",
                  }),
                }}
                isMulti
              />
              </div>
            </div>
            </div>
          </div>
          <ReactDatatable config={config} records={quizData} columns={columns} />
        </div>
      </div>
      {adminStore.loading && <Loader />}
    </div>
  );
}
);
export default QuizResult;
