import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Image, Modal } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Socket } from "socket.io-client";
import adminStore from "../store/AdminStore";
import ReactDatatable from "@mkikets/react-datatable";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import Swal from "sweetalert2";

const Sidebar = observer(() => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const [userData, setUserData] = useState();
  const isAuthenticated = secureLocalStorage.getItem("token");
  const [profileData, setProfileData] = useState({});
  const [userQuizScore, setUserQuizScore] = useState([]);

  useEffect(() => {
    if (userData?.id) {
      adminStore?.getUserQuizScoreById(userData?.id);
    }
  }, [userData]);

  useEffect(() => {
    if (adminStore?.data?.user_quiz_result) {
      setUserQuizScore(toJS(adminStore?.data?.user_quiz_result));
    }
  }, [adminStore?.data?.user_quiz_result]);

  useEffect(() => {
    setProfileData(toJS(adminStore?.data?.users));
  }, [adminStore?.data?.users]);

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Projects",
    no_data_text: "No Projects found!",
    button: {
      excel: true,
    },
  };

  useEffect(() => {
    if (isAuthenticated) {
      const decoded = jwtDecode(isAuthenticated);
      setUserData(decoded);
    }
  }, [isAuthenticated]);

  const columns = [
    {
      key: "sr_no",
      text: "Sr. No.",
      align: "center",
      sortable: false,
      cell: (record, index) => (
        <div style={{ textAlign: "center" }}>{index + 1}</div>
      )
    },    
    {
      key: "wallet_address",
      text: "Wallet Address",
      align: "left",
      sortable: true,
      cell: (record) => record?.wallet_address ? record?.wallet_address : "N/A",
    },
    {
      key: "quiz_name",
      text: "Quiz Name",
      align: "left",
      sortable: true,
    },
    {
      key: "score",
      text: "Your Score",
      align: "left",
      sortable: true,
      cell: (record) => `${record?.score}%`, // Append '%' to the percentage value
    },
   
  ];

  useEffect(() => {
    if (userData?.id) {
      adminStore.getUserById(userData?.id);
    }
  }, [userData]);

  const handleClose1 = () => setShow1(false);
  const handleLogin = () => {
    navigate("/login");
  };
  const handleLogout = () => {
    // Show confirmation alert
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out of your account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, log out",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: 'swal-confirm-red'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
  
        try {
          Socket.on("disconnect", () => {
            console.log("Disconnected from socket server");
          });
          Socket.close();
        } catch (e) {
          console.log(e, "errrr");
        }
  
        navigate("/login");  // Navigate to login page after logout
      }
    });
  };
  const showProfile = () => {
    setShow(true);
  };

  const showWallet = () => {
    setShow1(true);
  };

  const showResult = () => {
    setShow2(true);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "5.5%",
        background: "black",
        marginTop: "auto",
        borderRadius: "100px",
        justifyContent: "center",
        marginBottom: "auto",
      }}
      className="middle-page"
    >
      <ul
        style={{
          listStyleType: "none",
          padding: "15px",
          margin: 0,
          display: "flex",
          justifyContent: "space-between",
          justifyItems: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="ul-list"
      >
        <Dropdown align="end">
          <Dropdown.Toggle
            as="span"
            id="dropdown-profile"
            style={{
              cursor: "pointer",
              display: "inline-block",
              padding: 0,
            }}
          >
            {/* Profile SVG Icon */}
            <svg height="25" width="25" viewBox="0 0 512 512">
              <defs>
                <linearGradient
                  id="linear-gradient"
                  gradientUnits="userSpaceOnUse"
                  x1="74.98"
                  x2="437.02"
                  y1="74.98"
                  y2="437.02"
                >
                  <stop offset="0" stopColor="#21d1f7" />
                  <stop offset="1" stopColor="#2196f3" />
                </linearGradient>
              </defs>
              <g id="Layer_2" data-name="Layer 2">
                <g id="user">
                  <circle
                    id="background"
                    cx="256"
                    cy="256"
                    fill="url(#linear-gradient)"
                    r="256"
                  />
                  <g fill="#fff">
                    <path d="M330.34 391h-148.68a34.4 34.4 0 0 1 -34.4-34.4v-23.2a81.44 81.44 0 0 1 81.44-81.4h54.6a81.44 81.44 0 0 1 81.44 81.43v23.2a34.4 34.4 0 0 1 -34.4 34.37z" />
                    <circle cx="256" cy="178.93" r="57.93" />
                  </g>
                </g>
              </g>
            </svg>
          </Dropdown.Toggle>

          {/* Dropdown Menu */}
          <Dropdown.Menu
            style={{
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {userData ? (
              <div>
                <Dropdown.Item
                  href="#/profile"
                  className="dropdown-item-custom"
                  onClick={showProfile}
                  style={{
                    padding: "10px 20px",
                    transition: "background 0.3s, color 0.3s",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Inserted SVG Icon */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    height="16"
                    width="16"
                    style={{ marginRight: "8px" }}
                  >
                    <path
                      d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512h388.6c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                      fill="#333"
                    />
                  </svg>
                  Profile
                </Dropdown.Item>

                <Dropdown.Item
                  href="#/wallet"
                  onClick={showResult}
                  className="dropdown-item-custom"
                  style={{
                    padding: "10px 20px",
                    transition: "background 0.3s, color 0.3s",
                  }}
                >
                <i class="fas fa-poll" style={{height:"16" , width:"16" , marginRight:"8px"}}></i>
                  Result
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item-custom"
                  onClick={handleLogout}
                  style={{
                    padding: "10px 20px",
                    transition: "background 0.3s, color 0.3s",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    height="16"
                    width="16"
                    style={{ marginRight: "8px" }}
                  >
                    <path
                      d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32v256c0 17.7 14.3 32 32 32h64c17.7 0 32 14.3 32 32s-14.3 32-32 32h-64c-53 0-96-43-96-96V128C0 75 43 32 96 32h64c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
                      fill="#333"
                    />
                  </svg>
                  Logout
                </Dropdown.Item>
              </div>
            ) : (
              <div>
                {" "}
                <Dropdown.Item
                  className="dropdown-item-custom"
                  onClick={handleLogin}
                  style={{
                    padding: "10px 20px",
                    transition: "background 0.3s, color 0.3s",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    height="16"
                    width="16"
                    style={{ marginRight: "8px" }}
                  >
                    <path
                      d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32v256c0 17.7 14.3 32 32 32h64c17.7 0 32 14.3 32 32s-14.3 32-32 32h-64c-53 0-96-43-96-96V128C0 75 43 32 96 32h64c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
                      fill="#333"
                    />
                  </svg>
                  Login
                </Dropdown.Item>{" "}
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>

        <li style={{ margin: "10px 0" }}>
          <div
            className="d-flex learnn"
            style={{
              flexDirection: "column",
              alignItems: "center",
              padding: "4px",
            }}
          >
            <span
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#fff",
                letterSpacing: "2px",
                fontWeight: "500",
              }}
            >
              L
            </span>
            <span
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#fff",
                letterSpacing: "2px",
                fontWeight: "500",
              }}
            >
              E
            </span>
            <span
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#fff",
                letterSpacing: "2px",
                fontWeight: "500",
              }}
            >
              A
            </span>
            <span
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#fff",
                letterSpacing: "2px",
                fontWeight: "500",
              }}
            >
              R
            </span>
            <span
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#fff",
                letterSpacing: "2px",
                fontWeight: "500",
              }}
            >
              N
            </span>
          </div>
        </li>
        <li style={{ margin: "10px 0" }}>
          <img
            src="assets/images/Logo.png"
            alt="Profile"
            className="rounded-circle"
            width={70}
          />
        </li>
        <li className="earnbottom" style={{ marginBottom: "20px" }}>
          <div
            className="d-flex learnn"
            style={{
              flexDirection: "column",
              alignItems: "center",
              padding: "20px",
              // transform: "translateY(-10px)",
            }}
          >
            <span
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#fff",
                letterSpacing: "2px",
                fontWeight: "500",
              }}
            >
              E
            </span>
            <span
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#fff",
                letterSpacing: "2px",
                fontWeight: "500",
              }}
            >
              A
            </span>
            <span
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#fff",
                letterSpacing: "2px",
                fontWeight: "500",
              }}
            >
              R
            </span>
            <span
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#fff",
                letterSpacing: "2px",
                fontWeight: "500",
              }}
            >
              N
            </span>
          </div>
        </li>
      </ul>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center mb-3">
            <Image
              src="assets/images/logo.png"
              roundedCircle
              width="100"
              height="100"
            />
            <div style={{ marginLeft: "20px" }}>
              <h5>{`${profileData?.first_name} ${profileData?.last_name}`}</h5>
              <p>{profileData?.email}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* 
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Wallet Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Your Wallet Address</Form.Label>
              <Form.Control
                type="text"
                value={profileData?.wallet_address}
                readOnly
                onFocus={(e) => e.target.select()}
              />
            </Form.Group>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={show2} onHide={handleClose2} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Results</Modal.Title>
        </Modal.Header>
        <Modal.Body  style={{ overflowY: "auto"}}>
          <ReactDatatable records={userQuizScore} columns={columns} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});
export default Sidebar;
