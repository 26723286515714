import React, { Fragment, useEffect, useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { Modal, Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import adminStore from "../../store/AdminStore";
import AdminSidebar from "../../component/AdminSidebar";
import AdminHeader from "../../component/AdminHeader";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import Loader from "../../component/Loader";
import { BASE_FILE_URL } from "../../api/config";

const TruncatedCell = ({ text }) => {
  return (
    <span className="truncated-text" title={text}>
      {text.length > 20 ? `${text.slice(0, 20)}...` : text}
    </span>
  );
};

const Course = observer(() => {
  const [courseData, setCourseData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editCourse, setEditCourse] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);

  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    setFieldValue("course_thumbnail", file);
    if (file) {
      setThumbnailPreview(URL.createObjectURL(file));
    } else {
      setThumbnailPreview(null);
    }
  };
  useEffect(() => {
    adminStore?.getCourses();
  }, []);

  useEffect(() => {
    if (adminStore?.data?.courses) {
      setCourseData(toJS(adminStore?.data?.courses));
    }
  }, [adminStore?.data?.courses]);

  const validationSchema = Yup.object({
    course_name: Yup.string()
      .min(3, "Project name must be at least 3 characters")
      .required("Project name is required"),
    course_description: Yup.string()
      .min(10, "Project description must be at least 10 characters")
      .required("Project description is required"),
    course_overview: Yup.string()
      .min(5, "Project overview must be at least 5 characters")
      .required("Project overview is required"),
    course_thumbnail: Yup.mixed()
      .required("Thumbnail is required")
      .test(
        "fileType",
        "Only image files are allowed",
        (value) => value && ["image/jpeg", "image/png"].includes(value.type)
      ),
    // instagram: Yup.string().url("Invalid Instagram URL").nullable(),
    // facebook: Yup.string().url("Invalid Facebook URL").nullable(),
    // twitter: Yup.string().url("Invalid Twitter URL").nullable(),
    // youtube: Yup.string().url("Invalid YouTube URL").nullable(),
  });
  const validationSchemaEdit = Yup.object({
    course_name: Yup.string()
      .min(3, "Project name must be at least 3 characters")
      .required("Project name is required"),
    course_description: Yup.string()
      .min(10, "Project description must be at least 10 characters")
      .required("Project description is required"),
    course_overview: Yup.string()
      .min(5, "Project overview must be at least 5 characters")
      .required("Project overview is required"),

  });

  const handleToggleActive = (record) => {
    const updatedStatus = record.status === "true" ? "false" : "true";
    const requestData = {
      course_id: record.course_id,
      status: updatedStatus, // Send the new status as a string if required by the backend
    };

    try {
      adminStore.updateActive(requestData).then(() => {

        setCourseData((prevData) =>
          prevData.map((item) =>
            item.course_id === record.course_id
              ? { ...item, status: updatedStatus }
              : item
          )
        );
        swal("Success", "Status updated successfully!", "success");
      });
    } catch (err) {
      console.error("Failed to update status:", err);
      swal("Error", "Could not update the course status. Please try again.", "error");
    }
  };

  const navigationCallBackDelete = () => {
    adminStore.getCourses();
  }

  const handleEditCourse = (record) => {
    setEditCourse(record);
    setShowModal(true);
  };
  // const handleDeleteCourse = (record) => {
  //   console.log(record?.course_id,"record?.course_id");

  //   adminStore?.deleteCourse(record?.course_id, navigationCallBackDelete);
  // };

  const handleDeleteCourse = (record) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this project!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "btn btn-secondary",
          closeModal: true,
        },
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "btn btn-danger",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        adminStore?.deleteCourse(record?.course_id, navigationCallBackDelete)
          .then(() => {
            swal("Deleted!", "The project has been deleted successfully.", "success");
          })
          .catch((error) => {
            console.error("Error deleting project:", error);
            swal("Error!", "Failed to delete the project. Please try again.", "error");
          });
      } else {
        swal("Cancelled", "The project is not deleted", "info");
      }
    });
  };

  const columns = [
    {
      key: "course_id",
      text: "ID",
      align: "left",
      sortable: true,
    },
    {
      key: "course_name",
      text: "Project Name",
      align: "left",
      sortable: true,
    },
    {
      key: "course_description",
      text: "Project Description",
      align: "left",
      sortable: true,
    },
    {
      key: "course_overview",
      text: "Project Overview",
      align: "left",
      sortable: true,
      cell: (record) =>
        <TruncatedCell text={record?.course_overview} />
    },
    {
      key: "thumbnail",
      text: "Thumbnail",
      align: "left",
      sortable: true,
      cell: (record) =>
        record.thumbnail ? (
          <img
            src={
              BASE_FILE_URL +
              "uploads/course_thumbnail/" + record.thumbnail
            }
            alt="Thumbnail"
            style={{ width: "50px", height: "50px", objectFit: "cover" }}
          />
        ) : (
          <span>No Image</span>
        ),
    },
    {
      key: "add",
      text: "Add",
      width: 100,
      align: "center",
      sortable: true,
      cell: (record) => (
        <Fragment>
          <button
            className="btn btn-primary btn-sm mb-2"
            onClick={() => handleAddTopic(record)}
            style={{ marginRight: "5px" }}
          >
            <i className="fa-solid fa-plus mx-2"></i>
            Add Topic
          </button>
          <button
            className="btn btn-info btn-sm mb-2"
            onClick={() => handleAddQuiz(record)}
            style={{ marginRight: "5px" }}
          >
            <i className="fa-solid fa-plus mx-2"></i>
            Add Quiz
          </button>

          <button
            className={`btn btn-sm ${record?.status === "true" ? "btn-success" : "btn-danger"
              }`}
            onClick={() => handleToggleActive(record)}
          >
            {record?.status === "true" ? "Active" : "Inactive"}
          </button>
        </Fragment>
      ),
    },
    {
      key: "action",
      text: "Action",
      width: 100,
      align: "center",
      sortable: true,
      cell: (record) => (
        <Fragment>
          <button
            className="btn btn-primary btn-sm mb-2"
            onClick={() => handleEditCourse(record)}
            style={{ marginRight: "5px", width: "70px" }}
          >
            <i className="fa-solid fa-pen mx-2"></i>Edit
          </button>
          <button
            className="btn btn-danger btn-sm mb-2"
            onClick={() => handleDeleteCourse(record)}
            style={{ marginRight: "5px", width: "70px" }}
          >
            <i className="fa-solid fa-trash mx-2"></i>Delete
          </button>
        </Fragment>
      ),
    },
  ];

  // Configuration for ReactDatatable
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Projects",
    no_data_text: "No Projects found!",
    button: {
      excel: true,
    },
  };

  const navigate = useNavigate();

  const handleAddCourse = (values) => {
    const formData = new FormData();
    formData.append("course_id", editCourse ? editCourse.course_id : "");
    formData.append("course_name", values?.course_name);
    formData.append("course_description", values?.course_description);
    formData.append("course_overview", values?.course_overview);
    formData.append("instagram", values?.instagram);
    formData.append("facebook", values?.facebook);
    formData.append("twitter", values?.twitter);
    formData.append("youtube", values?.youtube);
    if (values?.course_thumbnail instanceof File) {
      formData.append("course_thumbnail", values?.course_thumbnail);
    }

    if (editCourse) {
      adminStore.updateCourse(formData).then((success) => {
        if (success) {
          setShowModal(false);
          swal("Success", "project updated successfully!", "success");
          adminStore.getCourses();
        } else {
          swal("Warning", "Failed to update the project. Please try again.", "warning");
        }

        setEditCourse();
      });
    } else {
      adminStore.addCourse(formData).then((success) => {
        if (success) {
          setShowModal(false);
          swal("Success", "Project added successfully!", "success");
          adminStore.getCourses();
        } else {
          swal("Warning", "Failed to add the project. Please try again.", "warning");
        }
      });
    }

  };

  const handleAddTopic = (record) => {
    navigate(`/admin/topic?id=${record.course_id}`);
  };
  const handleAddQuiz = (record) => {
    navigate(`/admin/addquiz?id=${record.course_id}`);
  };

  return (
    <div>
      <AdminHeader />
      <div style={{ display: "flex", flexDirection: "row", height: "92.2vh" , backgroundColor:"white"}}>
        <AdminSidebar />
        <div
          style={{
            flex: 1,
            overflow: "auto",
            backgroundColor: "#fff",
            padding: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Project List</h2>
            <Button
              variant="success"
              onClick={() => {
                setEditCourse(null);
                setShowModal(true);
              }}
            >
              Add Project
            </Button>
          </div>
          <ReactDatatable
            config={config}
            records={courseData}
            columns={columns}
          />
        </div>
      </div>

      <Modal show={showModal} onHide={() => { setEditCourse(null); setShowModal(false); setThumbnailPreview(null); }}>
        <Modal.Header closeButton>
          <Modal.Title>{editCourse ? "Edit Project" : "Add New Project"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              course_name: editCourse?.course_name || "",
              course_description: editCourse?.course_description || "",
              course_overview: editCourse?.course_overview || "",
              course_thumbnail: null,
              instagram: editCourse?.instagram || "",
              facebook: editCourse?.facebook || "",
              twitter: editCourse?.twitter || "",
              youtube: editCourse?.youtube || "",
            }}
            validationSchema={editCourse ? (validationSchemaEdit) : (validationSchema)}
            onSubmit={handleAddCourse}
          >
            {({ setFieldValue, errors }) => (
              <FormikForm>
                {console.log(errors, "err")
                }
                <Form.Group controlId="formCourseName">
                  <Form.Label>Project Name</Form.Label>
                  <Field
                    name="course_name"
                    className="form-control"
                    placeholder="Enter Project name"
                  />
                  <ErrorMessage
                    name="course_name"
                    component="div"
                    className="text-danger"
                  />
                     <Form.Group controlId="formCourseOverview" className="mt-3">
                  <Form.Label>Project Overview</Form.Label>
                  <Field
                    name="course_overview"
                    className="form-control"
                    placeholder="Enter Project overview"
                  />
                  <ErrorMessage
                    name="course_overview"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                </Form.Group>
                <Form.Group controlId="formCourseDescription" className="mt-3">
                  <Form.Label>Project Description</Form.Label>
                  <Field
                    name="course_description"
                    as="textarea"
                    className="form-control"
                    rows={3}
                    placeholder="Enter Project Description"
                  />
                  <ErrorMessage
                    name="course_description"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
             
                <Form.Group controlId="formCoursecourse_thumbnail" className="mt-3">
                  <Form.Label>Project Thumbnail</Form.Label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleImageChange(e, setFieldValue)}
                  />
                   {thumbnailPreview && (
                    <div className="mt-3">
                      <img src={thumbnailPreview} alt="Preview" style={{ width: "50px", height: "50px", objectFit: "cover" }} />
                    </div>
                  )}
                  <ErrorMessage
                    name="course_thumbnail"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <div className="row">
                  <div className="col-6">
                    <Form.Group controlId="formCourseName">
                      <Form.Label>Instagram URL</Form.Label>
                      <Field
                        name="instagram"
                        className="form-control"
                        placeholder="Enter instagram URL"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="formCourseName">
                      <Form.Label>Facebook URL</Form.Label>
                      <Field
                        name="facebook"
                        className="form-control"
                        placeholder="Enter facebook URL"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="formCourseName">
                      <Form.Label>Twitter URL</Form.Label>
                      <Field
                        name="twitter"
                        className="form-control"
                        placeholder="Enter Twitter URL"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group controlId="formCourseName">
                      <Form.Label>Youtube URL</Form.Label>
                      <Field
                        name="youtube"
                        className="form-control"
                        placeholder="Enter Youtube URL"
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="mt-3 d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" className="ms-2">
                    Save
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {adminStore.loading && <Loader />}
    </div>
  );
});

export default Course;
