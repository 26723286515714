import { Modal, Button, Form, InputGroup, Image } from "react-bootstrap";
import { observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import secureLocalStorage from "react-secure-storage";
import { jwtDecode } from "jwt-decode";
import Sidebar from "../component/Sidebar";

const OutreachPage = observer(() => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [socket, setSocket] = useState(null);
  const [userData, setUserData] = useState({});

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const navigate = useNavigate();

  const isAuthenticated = secureLocalStorage.getItem("token");

  useEffect(() => {
    const token = secureLocalStorage.getItem("token");
    if (token) {
      const newSocket = io("http://192.168.29.49:3000", {
        query: { token },
      });
      setSocket(newSocket);

      newSocket.on("connect", () => {
        console.log("Connected to socket server");
      });

      return () => {
        newSocket.close();
      };
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const decoded = jwtDecode(isAuthenticated);
      setUserData(decoded);
    }
  }, [isAuthenticated]);

  const handleLogout = () => {
    localStorage.clear();

    try {
      socket.on("disconnect", () => {
        console.log("Disconnected from socket server");
      });
      socket.close();
    } catch (e) {
      console.log(e, "errrr");
    }
    navigate("/login");
  };

  const showProfile = () => {
    setShow(true);
  };
  const showWallet = () => {
    setShow1(true);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      
      <div className="d-flex bg-light main-card">
        <Sidebar/>
        <div className="scroll-bar d-flex">
          <div
            className=" mx-auto card-x"
            style={{ maxWidth: "92%", padding: "20px", borderRadius: "10px" }}
          >
            <div className="project-card d-flex">
              <img
                alt="Image of a city with Bitcoin symbols"
                src="https://storage.googleapis.com/a1aa/image/vbqXjeiMJsw2PqpXN8cqLXJ4QXyy8v0pKbD8rjHoYefKAFbnA.jpg"
              />
              <div>
                <h2>Tactical Progress</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi.
                </p>
                <button
                  class="btn btn-primary"
                  style={{ backgroundColor: "#030B46" }}
                >
                  Get Started
                </button>
              </div>
            </div>

            <div class="project-card d-flex">
              <img
                alt="Image of a city with Bitcoin symbols"
                src="https://storage.googleapis.com/a1aa/image/vbqXjeiMJsw2PqpXN8cqLXJ4QXyy8v0pKbD8rjHoYefKAFbnA.jpg"
              />
              <div>
                <h2>Tactical Progress</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi.
                </p>
                <button
                  class="btn btn-primary"
                  style={{ backgroundColor: "#030B46" }}
                >
                  Get Started
                </button>
              </div>
            </div>

            <div class="project-card d-flex">
              <img
                alt="Image of a city with Bitcoin symbols"
                src="https://storage.googleapis.com/a1aa/image/vbqXjeiMJsw2PqpXN8cqLXJ4QXyy8v0pKbD8rjHoYefKAFbnA.jpg"
              />
              <div>
                <h2>Tactical Progress</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi.
                </p>
                <button
                  class="btn btn-primary"
                  style={{ backgroundColor: "#030B46" }}
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
        {isAuthenticated && (
        <>
          <button onClick={handleLogout} className="btn btn-danger">
            Logout
          </button>
          <button onClick={showProfile} className="btn btn-primary">
            Profile
          </button>
          <button onClick={showWallet} className="btn btn-success">
            Wallet
          </button>
        </>
      )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center mb-3">
            <Image
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3lqYismv8jTeVVEgSrAX78t107XY_ugOpCKCdsdpa6RoJPoztqAHSdriLx97zTTbTMOY&usqp=CAU"
              roundedCircle
              width="100"
              height="100"
            />
            <h5 className="ml-5" style={{ marginLeft: "20px" }}>
            {userData?.email}
            </h5>
          </div>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>
                Share some details about yourself with your riders.
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Wallet Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Your Wallet Address</Form.Label>
              <Form.Control
                type="text"
                value="24453213526352632632"
                readOnly
                onFocus={(e) => e.target.select()}
              />
            </Form.Group>
          </Form>

          <div className="wallet-connect mt-4">
            <h6>Connect Your Wallet</h6>
            <Button
              variant="primary"
              onClick={handleClose1}
              style={{ width: "100%" }}
            >
              Connect Wallet
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default OutreachPage;
