import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import adminStore from '../store/AdminStore';
import { toJS } from 'mobx';

function Dashboard() {
  const [activeTab, setActiveTab] = useState('dashboard');

  const [courseData , setCourseData] = useState(null);

  
  useEffect(()=>{
    adminStore.getCourses();
  },)
  useEffect(()=>{
    setCourseData(toJS(adminStore?.data?.courses))
  },[adminStore?.data?.courses])
  
  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return <table className="table table-striped">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Course Name</th>
            <th>Instructor</th>
          </tr>
        </thead>
        <tbody>
          {courseData?.map((course, index) => (
            <tr key={course.id}>
              <td>{course?.course_id}</td>
              <td>{course?.name}</td>
              <td>{course?.course_description}</td>
              
              <td>
                <button className="btn btn-primary btn-sm">Edit</button>
                <button className="btn btn-danger btn-sm ml-2">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      case 'courseData':
        return <h2>Course Data Section</h2>;
      case 'userData':
        return <h2>Add user Section</h2>;
        case 'quizData':
          return <h2>Add Quiz Section</h2>;
      default:
        return null;
    }
  };


  



  return (
    <div className="d-flex col-md-12">
      <div className="sidebar p-3 bg-light">
        <div className="d-flex align-items-center mb-4">
          <img
            src="assets/images/anik.png"
            alt="Profile picture of Maxine Kennedy"
            className="rounded-circle me-3"
            width="50"
            height="50"
          />
          <div>
            <h5 className="mb-0">Learn And Earn</h5>
            <small className="text-muted">Admin Head</small>
          </div>
        </div>
        <nav className="nav flex-column">
          <a
            href="#"
            className={`nav-link ${activeTab === 'dashboard' ? 'active' : ''}`}
            onClick={() => setActiveTab('dashboard')}
          >
            <i className="fas fa-tachometer-alt"></i> Dashboard
          </a>
          <a
            href="#"
            className={`nav-link ${activeTab === 'courseData' ? 'active' : ''}`}
            onClick={() => setActiveTab('courseData')}
          >
            <i className="fas fa-th"></i> Course
          </a>
          <a
            href="#"
            className={`nav-link ${activeTab === 'userData' ? 'active' : ''}`}
            onClick={() => setActiveTab('userData')}
          >
            <i className="fas fa-envelope"></i> user
          </a>
          <a
            href="#"
            className={`nav-link ${activeTab === 'quizData' ? 'active' : ''}`}
            onClick={() => setActiveTab('quizData')}
          >
            <i className="fas fa-envelope"></i> Quiz
          </a>
        </nav>
      </div>
      <div className="content p-3">
        {renderContent()}
      </div>
    </div>
  );
}

export default Dashboard;
