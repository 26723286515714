import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';


const ProfilePage = observer(() => {
    return (
        <div style={{
            width: "100%",
            background: "radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(15, 174, 230, 1) 100%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <div className='d-flex bg-light main-card mt-5' >
                <div style={{ width: '10%', background: 'rgba(8, 158, 219, 0.05)', padding: '20px', borderRadius: "100px" }} className='middle-page'>
                    <ul style={{ listStyleType: 'none', padding: 0, height: "100vh", margin: 0, display: 'flex', justifyContent: "space-between", justifyItems: "center", flexDirection: "column", alignItems: "center" }} className='ul-list'>
                    <li style={{ margin: '10px 0' }}>
                            <div className="d-flex learnn" style={{ flexDirection: "column", alignItems: "center", padding: "20px" }}>
                                <span style={{ fontSize: "30px", fontWeight: "bold", color: "rgba(79, 204, 242, 0.25)", letterSpacing: "5px", fontWeight: "700" }}>L</span>
                                <span style={{ fontSize: "30px", fontWeight: "bold", color: "rgba(79, 204, 242, 0.25)", letterSpacing: "5px", fontWeight: "700" }}>E</span>
                                <span style={{ fontSize: "30px", fontWeight: "bold", color: "rgba(79, 204, 242, 0.25)", letterSpacing: "5px", fontWeight: "700" }}>A</span>
                                <span style={{ fontSize: "30px", fontWeight: "bold", color: "rgba(79, 204, 242, 0.25)", letterSpacing: "5px", fontWeight: "700" }}>R</span>
                                <span style={{ fontSize: "30px", fontWeight: "bold", color: "rgba(79, 204, 242, 0.25)", letterSpacing: "5px", fontWeight: "700" }}>N</span>
                               </div>
                            

                        </li>
                        <li style={{ margin: '10px 0' }}>
                            <img
                                    src="assets/images/Logo_2.png"
                                    alt="Profile"
                                    className="rounded-circle"
                                    width={60}
                                />
                            

                        </li>
                        <li style={{ margin: '10px 0' }}>
                            <div className="d-flex learnn" style={{ flexDirection: "column", alignItems: "center", padding: "20px" }}>
                                
                                <span style={{ fontSize: "30px", fontWeight: "bold", color: "rgba(79, 204, 242, 0.25)", letterSpacing: "5px", fontWeight: "700" }}>E</span>
                                <span style={{ fontSize: "30px", fontWeight: "bold", color: "rgba(79, 204, 242, 0.25)", letterSpacing: "5px", fontWeight: "700" }}>A</span>
                                <span style={{ fontSize: "30px", fontWeight: "bold", color: "rgba(79, 204, 242, 0.25)", letterSpacing: "5px", fontWeight: "700" }}>R</span>
                                <span style={{ fontSize: "30px", fontWeight: "bold", color: "rgba(79, 204, 242, 0.25)", letterSpacing: "5px", fontWeight: "700" }}>N</span>
                            </div>
                            

                        </li>
                    </ul>
                </div>
                <div style={{ width: '90%', background: '#fff', padding: '20px', borderRadius: "100px" }}>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-6 ">
                                <div className="card mb-4 card-video">
                                    <div className="card-body" style={{padding:"20px"}}>
                                        <div className='d-flex'>
                               <div style={{ display: "flex", flexDirection: "column" }}>
                                            <h3 className="card-title" style={{ color: "#000", fontSize: "24px", fontWeight: "bold", textAlign: "center", margin: "10px 0" }}>
                                                Course 1
                                                
                                            </h3>
                                            <div className='d-flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <g clip-path="url(#clip0_43_1204)">
                                                        <path d="M15.5521 5.80296L10.6578 5.09181L8.46903 0.656838C8.27759 0.2689 7.72256 0.268713 7.53097 0.656838L5.34222 5.09181L0.448 5.80296C0.0199692 5.86518 -0.151937 6.39293 0.158125 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C16.1517 6.39315 15.9804 5.86524 15.5521 5.80296Z" fill="#F7AC16" />
                                                        <path d="M8.00003 0.36528C7.80053 0.36528 7.61891 0.478686 7.53094 0.656843L5.34219 5.09181L0.448 5.80297C0.0199692 5.86519 -0.151937 6.39294 0.158125 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717H8.00003V0.36528Z" fill="#F2D422" />
                                                        <path d="M0.448 5.80297C0.0199692 5.86519 -0.151937 6.39294 0.158125 6.69513L3.69959 10.1473L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.7293 15.6332 12.8373 15.5995 12.9284 15.5333L5.34219 5.09178L0.448 5.80297Z" fill="#F7AC16" />
                                                        <path d="M5.57994 9.54515C1.78638 5.84731 1.94944 6.0455 1.89853 5.85372C1.87781 5.77669 1.87356 5.68706 1.89609 5.59253L0.448 5.80294C0.0199692 5.86515 -0.151937 6.3929 0.158125 6.69509L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77713 14.7772 4.71331 14.5977 4.74388 14.4196L5.57994 9.54515Z" fill="#F48B19" />
                                                        <path d="M0.158124 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L0.0257181 6.15894C-0.0354381 6.37815 0.0257806 6.56619 0.158124 6.69512Z" fill="#F2D422" />
                                                        <path d="M0.158124 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77712 14.7772 4.71331 14.5977 4.74388 14.4196L5.57994 9.54515L3.14625 7.17287L0.0257181 6.15894C-0.0354382 6.37815 0.0257806 6.56619 0.158124 6.69512Z" fill="#EFB616" />
                                                        <path d="M2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C15.9845 6.55615 16.0357 6.34828 15.9743 6.15897L3.69959 10.1472L2.86353 15.0217Z" fill="#F7AC16" />
                                                        <path d="M2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77713 14.7772 4.71331 14.5977 4.74388 14.4196L5.57994 9.54515L5.57309 9.5385L3.69959 10.1472L2.86353 15.0217Z" fill="#F48B19" />
                                                        <path d="M3.62247 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C16.1516 6.39316 15.9804 5.86525 15.552 5.80297L10.6578 5.09181L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62247 15.5731Z" fill="#F2D422" />
                                                        <path d="M15.9743 6.15894L8 8.74994L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C15.9845 6.55612 16.0358 6.34828 15.9743 6.15894Z" fill="#F7AC16" />
                                                        <path d="M12.3005 10.1473L8 8.74994L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3005 10.1473Z" fill="#F2D422" />
                                                        <path d="M3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.7293 15.6332 12.8373 15.5995 12.9284 15.5333L8 8.74994L3.07162 15.5333Z" fill="#F7AC16" />
                                                        <path d="M8.00003 8.75L8 8.74994L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717H8.00003V8.75Z" fill="#F2D422" />
                                                        <path d="M5.01059 12.8645L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77712 14.7772 4.71331 14.5977 4.74387 14.4196L5.01059 12.8645Z" fill="#EFB616" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_43_1204">
                                                            <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p style={{ color: "black", marginLeft: "10px" }}>4.8</p>
                                            </div>
                                            <div className='d-flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <g clip-path="url(#clip0_43_1235)">
                                                        <path d="M14.5625 12.25H12.6875C12.4286 12.25 12.2188 12.0401 12.2188 11.7812V0.46875C12.2188 0.209875 12.4286 0 12.6875 0H14.5625C14.8214 0 15.0312 0.209875 15.0312 0.46875V11.7812C15.0312 12.0401 14.8214 12.25 14.5625 12.25Z" fill="#263238" />
                                                        <path d="M10.8125 12.25H8.9375C8.67862 12.25 8.46875 12.0401 8.46875 11.7812V2.40625C8.46875 2.14737 8.67862 1.9375 8.9375 1.9375H10.8125C11.0714 1.9375 11.2812 2.14737 11.2812 2.40625V11.7812C11.2812 12.0401 11.0714 12.25 10.8125 12.25Z" fill="#263238" />
                                                        <path d="M7.0625 12.25H5.1875C4.92862 12.25 4.71875 12.0401 4.71875 11.7812V4.28125C4.71875 4.02237 4.92862 3.8125 5.1875 3.8125H7.0625C7.32137 3.8125 7.53125 4.02237 7.53125 4.28125V11.7812C7.53125 12.0401 7.32137 12.25 7.0625 12.25Z" fill="#263238" />
                                                        <path d="M3.3125 12.25H1.4375C1.17862 12.25 0.96875 12.0401 0.96875 11.7812V6.15625C0.96875 5.89737 1.17862 5.6875 1.4375 5.6875H3.3125C3.57137 5.6875 3.78125 5.89737 3.78125 6.15625V11.7812C3.78125 12.0401 3.57137 12.25 3.3125 12.25Z" fill="#263238" />
                                                        <path d="M7.53125 14.5938C7.53125 14.7541 7.5475 14.9109 7.57844 15.0625H0.46875C0.21 15.0625 0 14.8525 0 14.5938C0 14.335 0.21 14.125 0.46875 14.125H7.57844C7.5475 14.2766 7.53125 14.4334 7.53125 14.5938Z" fill="#263238" />
                                                        <path d="M16 14.5938C16 14.8525 15.79 15.0625 15.5312 15.0625H12.1716C12.2025 14.9109 12.2188 14.7541 12.2188 14.5938C12.2188 14.4334 12.2025 14.2766 12.1716 14.125H15.5312C15.79 14.125 16 14.335 16 14.5938Z" fill="#263238" />
                                                        <path d="M9.875 16C9.09959 16 8.46875 15.3692 8.46875 14.5938C8.46875 13.8183 9.09959 13.1875 9.875 13.1875C10.6504 13.1875 11.2812 13.8183 11.2812 14.5938C11.2812 15.3692 10.6504 16 9.875 16Z" fill="#263238" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_43_1235">
                                                            <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p style={{ color: "black", marginLeft: "10px" }}>Beginner</p>
                                            </div>
                                            <div className='d-flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <g clip-path="url(#clip0_43_1253)">
                                                        <path d="M7.36606 4.08176C5.20075 4.08176 3.43916 5.84335 3.43916 8.00866C3.43916 10.174 5.20075 11.9356 7.36606 11.9356C9.53137 11.9356 11.293 10.174 11.293 8.00866C11.293 5.84335 9.53137 4.08176 7.36606 4.08176ZM7.36606 10.9981C5.71769 10.9981 4.37666 9.65707 4.37666 8.00869C4.37666 6.36032 5.71769 5.01929 7.36606 5.01929C9.01444 5.01929 10.3555 6.36032 10.3555 8.00869C10.3555 9.65707 9.01444 10.9981 7.36606 10.9981Z" fill="black" />
                                                        <path d="M15.8627 7.24441C15.6797 7.06138 15.3828 7.06138 15.1998 7.24441L14.7264 7.71779C14.6568 5.8561 13.8992 4.116 12.5746 2.79141C11.1833 1.40013 9.33356 0.633942 7.36603 0.633942C5.3985 0.633942 3.54875 1.40016 2.15747 2.79141C0.766188 4.18266 0 6.03247 0 8C0 9.96754 0.766219 11.8173 2.15747 13.2086C3.54872 14.5999 5.39853 15.3661 7.36606 15.3661C7.62494 15.3661 7.83481 15.1562 7.83481 14.8973C7.83481 14.6384 7.62494 14.4286 7.36606 14.4286C3.82134 14.4286 0.9375 11.5447 0.9375 8C0.9375 4.45529 3.82134 1.57144 7.36606 1.57144C10.8119 1.57144 13.6329 4.29675 13.7876 7.70507L13.3269 7.24441C13.1439 7.06138 12.8471 7.06138 12.664 7.24441C12.481 7.42744 12.481 7.72425 12.664 7.90732L13.9319 9.17519C14.0234 9.26672 14.1434 9.3125 14.2633 9.3125C14.3833 9.3125 14.5033 9.26672 14.5948 9.17519L15.8627 7.90732C16.0458 7.72429 16.0458 7.42747 15.8627 7.24441Z" fill="black" />
                                                        <path d="M7.83481 7.80584V6.625C7.83481 6.36612 7.62494 6.15625 7.36606 6.15625C7.10719 6.15625 6.89731 6.36612 6.89731 6.625V8C6.89731 8.12434 6.94669 8.24353 7.03462 8.33144L7.66409 8.96094C7.75562 9.0525 7.87559 9.09825 7.99553 9.09825C8.11547 9.09825 8.23547 9.05247 8.32697 8.96094C8.51 8.77791 8.51003 8.48112 8.32697 8.29803L7.83481 7.80584Z" fill="black" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_43_1253">
                                                            <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p style={{ color: "black", marginLeft: "10px" }}>6 Month</p>
                                            </div>
                                        </div>
                                        <img
                                    width={200}
                                    src="assets/images/youtue_img.png"
                                    alt="Video Illustration"
                                    className="img-fluid my-3"
                                />
                                        </div>
                                        <a href="#" className="btn btn-primary">Go somewhere</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 ">
                                <div className="card mb-4 card-video">
                                    <div className="card-body" style={{padding:"20px"}}>
                                        <div className='d-flex'>
                               <div style={{ display: "flex", flexDirection: "column" }}>
                                            <h3 className="card-title" style={{ color: "#000", fontSize: "24px", fontWeight: "bold", textAlign: "center", margin: "10px 0" }}>
                                                Course 2
                                                
                                            </h3>
                                            <div className='d-flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <g clip-path="url(#clip0_43_1204)">
                                                        <path d="M15.5521 5.80296L10.6578 5.09181L8.46903 0.656838C8.27759 0.2689 7.72256 0.268713 7.53097 0.656838L5.34222 5.09181L0.448 5.80296C0.0199692 5.86518 -0.151937 6.39293 0.158125 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C16.1517 6.39315 15.9804 5.86524 15.5521 5.80296Z" fill="#F7AC16" />
                                                        <path d="M8.00003 0.36528C7.80053 0.36528 7.61891 0.478686 7.53094 0.656843L5.34219 5.09181L0.448 5.80297C0.0199692 5.86519 -0.151937 6.39294 0.158125 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717H8.00003V0.36528Z" fill="#F2D422" />
                                                        <path d="M0.448 5.80297C0.0199692 5.86519 -0.151937 6.39294 0.158125 6.69513L3.69959 10.1473L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.7293 15.6332 12.8373 15.5995 12.9284 15.5333L5.34219 5.09178L0.448 5.80297Z" fill="#F7AC16" />
                                                        <path d="M5.57994 9.54515C1.78638 5.84731 1.94944 6.0455 1.89853 5.85372C1.87781 5.77669 1.87356 5.68706 1.89609 5.59253L0.448 5.80294C0.0199692 5.86515 -0.151937 6.3929 0.158125 6.69509L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77713 14.7772 4.71331 14.5977 4.74388 14.4196L5.57994 9.54515Z" fill="#F48B19" />
                                                        <path d="M0.158124 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L0.0257181 6.15894C-0.0354381 6.37815 0.0257806 6.56619 0.158124 6.69512Z" fill="#F2D422" />
                                                        <path d="M0.158124 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77712 14.7772 4.71331 14.5977 4.74388 14.4196L5.57994 9.54515L3.14625 7.17287L0.0257181 6.15894C-0.0354382 6.37815 0.0257806 6.56619 0.158124 6.69512Z" fill="#EFB616" />
                                                        <path d="M2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C15.9845 6.55615 16.0357 6.34828 15.9743 6.15897L3.69959 10.1472L2.86353 15.0217Z" fill="#F7AC16" />
                                                        <path d="M2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77713 14.7772 4.71331 14.5977 4.74388 14.4196L5.57994 9.54515L5.57309 9.5385L3.69959 10.1472L2.86353 15.0217Z" fill="#F48B19" />
                                                        <path d="M3.62247 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C16.1516 6.39316 15.9804 5.86525 15.552 5.80297L10.6578 5.09181L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62247 15.5731Z" fill="#F2D422" />
                                                        <path d="M15.9743 6.15894L8 8.74994L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C15.9845 6.55612 16.0358 6.34828 15.9743 6.15894Z" fill="#F7AC16" />
                                                        <path d="M12.3005 10.1473L8 8.74994L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3005 10.1473Z" fill="#F2D422" />
                                                        <path d="M3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.7293 15.6332 12.8373 15.5995 12.9284 15.5333L8 8.74994L3.07162 15.5333Z" fill="#F7AC16" />
                                                        <path d="M8.00003 8.75L8 8.74994L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717H8.00003V8.75Z" fill="#F2D422" />
                                                        <path d="M5.01059 12.8645L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77712 14.7772 4.71331 14.5977 4.74387 14.4196L5.01059 12.8645Z" fill="#EFB616" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_43_1204">
                                                            <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p style={{ color: "black", marginLeft: "10px" }}>4.8</p>
                                            </div>
                                            <div className='d-flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <g clip-path="url(#clip0_43_1235)">
                                                        <path d="M14.5625 12.25H12.6875C12.4286 12.25 12.2188 12.0401 12.2188 11.7812V0.46875C12.2188 0.209875 12.4286 0 12.6875 0H14.5625C14.8214 0 15.0312 0.209875 15.0312 0.46875V11.7812C15.0312 12.0401 14.8214 12.25 14.5625 12.25Z" fill="#263238" />
                                                        <path d="M10.8125 12.25H8.9375C8.67862 12.25 8.46875 12.0401 8.46875 11.7812V2.40625C8.46875 2.14737 8.67862 1.9375 8.9375 1.9375H10.8125C11.0714 1.9375 11.2812 2.14737 11.2812 2.40625V11.7812C11.2812 12.0401 11.0714 12.25 10.8125 12.25Z" fill="#263238" />
                                                        <path d="M7.0625 12.25H5.1875C4.92862 12.25 4.71875 12.0401 4.71875 11.7812V4.28125C4.71875 4.02237 4.92862 3.8125 5.1875 3.8125H7.0625C7.32137 3.8125 7.53125 4.02237 7.53125 4.28125V11.7812C7.53125 12.0401 7.32137 12.25 7.0625 12.25Z" fill="#263238" />
                                                        <path d="M3.3125 12.25H1.4375C1.17862 12.25 0.96875 12.0401 0.96875 11.7812V6.15625C0.96875 5.89737 1.17862 5.6875 1.4375 5.6875H3.3125C3.57137 5.6875 3.78125 5.89737 3.78125 6.15625V11.7812C3.78125 12.0401 3.57137 12.25 3.3125 12.25Z" fill="#263238" />
                                                        <path d="M7.53125 14.5938C7.53125 14.7541 7.5475 14.9109 7.57844 15.0625H0.46875C0.21 15.0625 0 14.8525 0 14.5938C0 14.335 0.21 14.125 0.46875 14.125H7.57844C7.5475 14.2766 7.53125 14.4334 7.53125 14.5938Z" fill="#263238" />
                                                        <path d="M16 14.5938C16 14.8525 15.79 15.0625 15.5312 15.0625H12.1716C12.2025 14.9109 12.2188 14.7541 12.2188 14.5938C12.2188 14.4334 12.2025 14.2766 12.1716 14.125H15.5312C15.79 14.125 16 14.335 16 14.5938Z" fill="#263238" />
                                                        <path d="M9.875 16C9.09959 16 8.46875 15.3692 8.46875 14.5938C8.46875 13.8183 9.09959 13.1875 9.875 13.1875C10.6504 13.1875 11.2812 13.8183 11.2812 14.5938C11.2812 15.3692 10.6504 16 9.875 16Z" fill="#263238" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_43_1235">
                                                            <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p style={{ color: "black", marginLeft: "10px" }}>Beginner</p>
                                            </div>
                                            <div className='d-flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <g clip-path="url(#clip0_43_1253)">
                                                        <path d="M7.36606 4.08176C5.20075 4.08176 3.43916 5.84335 3.43916 8.00866C3.43916 10.174 5.20075 11.9356 7.36606 11.9356C9.53137 11.9356 11.293 10.174 11.293 8.00866C11.293 5.84335 9.53137 4.08176 7.36606 4.08176ZM7.36606 10.9981C5.71769 10.9981 4.37666 9.65707 4.37666 8.00869C4.37666 6.36032 5.71769 5.01929 7.36606 5.01929C9.01444 5.01929 10.3555 6.36032 10.3555 8.00869C10.3555 9.65707 9.01444 10.9981 7.36606 10.9981Z" fill="black" />
                                                        <path d="M15.8627 7.24441C15.6797 7.06138 15.3828 7.06138 15.1998 7.24441L14.7264 7.71779C14.6568 5.8561 13.8992 4.116 12.5746 2.79141C11.1833 1.40013 9.33356 0.633942 7.36603 0.633942C5.3985 0.633942 3.54875 1.40016 2.15747 2.79141C0.766188 4.18266 0 6.03247 0 8C0 9.96754 0.766219 11.8173 2.15747 13.2086C3.54872 14.5999 5.39853 15.3661 7.36606 15.3661C7.62494 15.3661 7.83481 15.1562 7.83481 14.8973C7.83481 14.6384 7.62494 14.4286 7.36606 14.4286C3.82134 14.4286 0.9375 11.5447 0.9375 8C0.9375 4.45529 3.82134 1.57144 7.36606 1.57144C10.8119 1.57144 13.6329 4.29675 13.7876 7.70507L13.3269 7.24441C13.1439 7.06138 12.8471 7.06138 12.664 7.24441C12.481 7.42744 12.481 7.72425 12.664 7.90732L13.9319 9.17519C14.0234 9.26672 14.1434 9.3125 14.2633 9.3125C14.3833 9.3125 14.5033 9.26672 14.5948 9.17519L15.8627 7.90732C16.0458 7.72429 16.0458 7.42747 15.8627 7.24441Z" fill="black" />
                                                        <path d="M7.83481 7.80584V6.625C7.83481 6.36612 7.62494 6.15625 7.36606 6.15625C7.10719 6.15625 6.89731 6.36612 6.89731 6.625V8C6.89731 8.12434 6.94669 8.24353 7.03462 8.33144L7.66409 8.96094C7.75562 9.0525 7.87559 9.09825 7.99553 9.09825C8.11547 9.09825 8.23547 9.05247 8.32697 8.96094C8.51 8.77791 8.51003 8.48112 8.32697 8.29803L7.83481 7.80584Z" fill="black" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_43_1253">
                                                            <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p style={{ color: "black", marginLeft: "10px" }}>6 Month</p>
                                            </div>
                                        </div>
                                        <img
                                    width={200}
                                    src="assets/images/youtue_img.png"
                                    alt="Video Illustration"
                                    className="img-fluid my-3"
                                />
                                        </div>
                                        <a href="#" className="btn btn-primary">Go somewhere</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 ">
                                <div className="card mb-4 card-video">
                                    <div className="card-body" style={{padding:"20px"}}>
                                        <div className='d-flex'>
                               <div style={{ display: "flex", flexDirection: "column" }}>
                                            <h3 className="card-title" style={{ color: "#000", fontSize: "24px", fontWeight: "bold", textAlign: "center", margin: "10px 0" }}>
                                                Course 3
                                            </h3>
                                            <div className='d-flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <g clip-path="url(#clip0_43_1204)">
                                                        <path d="M15.5521 5.80296L10.6578 5.09181L8.46903 0.656838C8.27759 0.2689 7.72256 0.268713 7.53097 0.656838L5.34222 5.09181L0.448 5.80296C0.0199692 5.86518 -0.151937 6.39293 0.158125 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C16.1517 6.39315 15.9804 5.86524 15.5521 5.80296Z" fill="#F7AC16" />
                                                        <path d="M8.00003 0.36528C7.80053 0.36528 7.61891 0.478686 7.53094 0.656843L5.34219 5.09181L0.448 5.80297C0.0199692 5.86519 -0.151937 6.39294 0.158125 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717H8.00003V0.36528Z" fill="#F2D422" />
                                                        <path d="M0.448 5.80297C0.0199692 5.86519 -0.151937 6.39294 0.158125 6.69513L3.69959 10.1473L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.7293 15.6332 12.8373 15.5995 12.9284 15.5333L5.34219 5.09178L0.448 5.80297Z" fill="#F7AC16" />
                                                        <path d="M5.57994 9.54515C1.78638 5.84731 1.94944 6.0455 1.89853 5.85372C1.87781 5.77669 1.87356 5.68706 1.89609 5.59253L0.448 5.80294C0.0199692 5.86515 -0.151937 6.3929 0.158125 6.69509L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77713 14.7772 4.71331 14.5977 4.74388 14.4196L5.57994 9.54515Z" fill="#F48B19" />
                                                        <path d="M0.158124 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L0.0257181 6.15894C-0.0354381 6.37815 0.0257806 6.56619 0.158124 6.69512Z" fill="#F2D422" />
                                                        <path d="M0.158124 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77712 14.7772 4.71331 14.5977 4.74388 14.4196L5.57994 9.54515L3.14625 7.17287L0.0257181 6.15894C-0.0354382 6.37815 0.0257806 6.56619 0.158124 6.69512Z" fill="#EFB616" />
                                                        <path d="M2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C15.9845 6.55615 16.0357 6.34828 15.9743 6.15897L3.69959 10.1472L2.86353 15.0217Z" fill="#F7AC16" />
                                                        <path d="M2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77713 14.7772 4.71331 14.5977 4.74388 14.4196L5.57994 9.54515L5.57309 9.5385L3.69959 10.1472L2.86353 15.0217Z" fill="#F48B19" />
                                                        <path d="M3.62247 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C16.1516 6.39316 15.9804 5.86525 15.552 5.80297L10.6578 5.09181L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62247 15.5731Z" fill="#F2D422" />
                                                        <path d="M15.9743 6.15894L8 8.74994L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C15.9845 6.55612 16.0358 6.34828 15.9743 6.15894Z" fill="#F7AC16" />
                                                        <path d="M12.3005 10.1473L8 8.74994L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3005 10.1473Z" fill="#F2D422" />
                                                        <path d="M3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.7293 15.6332 12.8373 15.5995 12.9284 15.5333L8 8.74994L3.07162 15.5333Z" fill="#F7AC16" />
                                                        <path d="M8.00003 8.75L8 8.74994L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717H8.00003V8.75Z" fill="#F2D422" />
                                                        <path d="M5.01059 12.8645L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77712 14.7772 4.71331 14.5977 4.74387 14.4196L5.01059 12.8645Z" fill="#EFB616" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_43_1204">
                                                            <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p style={{ color: "black", marginLeft: "10px" }}>4.8</p>
                                            </div>
                                            <div className='d-flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <g clip-path="url(#clip0_43_1235)">
                                                        <path d="M14.5625 12.25H12.6875C12.4286 12.25 12.2188 12.0401 12.2188 11.7812V0.46875C12.2188 0.209875 12.4286 0 12.6875 0H14.5625C14.8214 0 15.0312 0.209875 15.0312 0.46875V11.7812C15.0312 12.0401 14.8214 12.25 14.5625 12.25Z" fill="#263238" />
                                                        <path d="M10.8125 12.25H8.9375C8.67862 12.25 8.46875 12.0401 8.46875 11.7812V2.40625C8.46875 2.14737 8.67862 1.9375 8.9375 1.9375H10.8125C11.0714 1.9375 11.2812 2.14737 11.2812 2.40625V11.7812C11.2812 12.0401 11.0714 12.25 10.8125 12.25Z" fill="#263238" />
                                                        <path d="M7.0625 12.25H5.1875C4.92862 12.25 4.71875 12.0401 4.71875 11.7812V4.28125C4.71875 4.02237 4.92862 3.8125 5.1875 3.8125H7.0625C7.32137 3.8125 7.53125 4.02237 7.53125 4.28125V11.7812C7.53125 12.0401 7.32137 12.25 7.0625 12.25Z" fill="#263238" />
                                                        <path d="M3.3125 12.25H1.4375C1.17862 12.25 0.96875 12.0401 0.96875 11.7812V6.15625C0.96875 5.89737 1.17862 5.6875 1.4375 5.6875H3.3125C3.57137 5.6875 3.78125 5.89737 3.78125 6.15625V11.7812C3.78125 12.0401 3.57137 12.25 3.3125 12.25Z" fill="#263238" />
                                                        <path d="M7.53125 14.5938C7.53125 14.7541 7.5475 14.9109 7.57844 15.0625H0.46875C0.21 15.0625 0 14.8525 0 14.5938C0 14.335 0.21 14.125 0.46875 14.125H7.57844C7.5475 14.2766 7.53125 14.4334 7.53125 14.5938Z" fill="#263238" />
                                                        <path d="M16 14.5938C16 14.8525 15.79 15.0625 15.5312 15.0625H12.1716C12.2025 14.9109 12.2188 14.7541 12.2188 14.5938C12.2188 14.4334 12.2025 14.2766 12.1716 14.125H15.5312C15.79 14.125 16 14.335 16 14.5938Z" fill="#263238" />
                                                        <path d="M9.875 16C9.09959 16 8.46875 15.3692 8.46875 14.5938C8.46875 13.8183 9.09959 13.1875 9.875 13.1875C10.6504 13.1875 11.2812 13.8183 11.2812 14.5938C11.2812 15.3692 10.6504 16 9.875 16Z" fill="#263238" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_43_1235">
                                                            <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p style={{ color: "black", marginLeft: "10px" }}>Beginner</p>
                                            </div>
                                            <div className='d-flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <g clip-path="url(#clip0_43_1253)">
                                                        <path d="M7.36606 4.08176C5.20075 4.08176 3.43916 5.84335 3.43916 8.00866C3.43916 10.174 5.20075 11.9356 7.36606 11.9356C9.53137 11.9356 11.293 10.174 11.293 8.00866C11.293 5.84335 9.53137 4.08176 7.36606 4.08176ZM7.36606 10.9981C5.71769 10.9981 4.37666 9.65707 4.37666 8.00869C4.37666 6.36032 5.71769 5.01929 7.36606 5.01929C9.01444 5.01929 10.3555 6.36032 10.3555 8.00869C10.3555 9.65707 9.01444 10.9981 7.36606 10.9981Z" fill="black" />
                                                        <path d="M15.8627 7.24441C15.6797 7.06138 15.3828 7.06138 15.1998 7.24441L14.7264 7.71779C14.6568 5.8561 13.8992 4.116 12.5746 2.79141C11.1833 1.40013 9.33356 0.633942 7.36603 0.633942C5.3985 0.633942 3.54875 1.40016 2.15747 2.79141C0.766188 4.18266 0 6.03247 0 8C0 9.96754 0.766219 11.8173 2.15747 13.2086C3.54872 14.5999 5.39853 15.3661 7.36606 15.3661C7.62494 15.3661 7.83481 15.1562 7.83481 14.8973C7.83481 14.6384 7.62494 14.4286 7.36606 14.4286C3.82134 14.4286 0.9375 11.5447 0.9375 8C0.9375 4.45529 3.82134 1.57144 7.36606 1.57144C10.8119 1.57144 13.6329 4.29675 13.7876 7.70507L13.3269 7.24441C13.1439 7.06138 12.8471 7.06138 12.664 7.24441C12.481 7.42744 12.481 7.72425 12.664 7.90732L13.9319 9.17519C14.0234 9.26672 14.1434 9.3125 14.2633 9.3125C14.3833 9.3125 14.5033 9.26672 14.5948 9.17519L15.8627 7.90732C16.0458 7.72429 16.0458 7.42747 15.8627 7.24441Z" fill="black" />
                                                        <path d="M7.83481 7.80584V6.625C7.83481 6.36612 7.62494 6.15625 7.36606 6.15625C7.10719 6.15625 6.89731 6.36612 6.89731 6.625V8C6.89731 8.12434 6.94669 8.24353 7.03462 8.33144L7.66409 8.96094C7.75562 9.0525 7.87559 9.09825 7.99553 9.09825C8.11547 9.09825 8.23547 9.05247 8.32697 8.96094C8.51 8.77791 8.51003 8.48112 8.32697 8.29803L7.83481 7.80584Z" fill="black" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_43_1253">
                                                            <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p style={{ color: "black", marginLeft: "10px" }}>6 Month</p>
                                            </div>
                                        </div>
                                        <img
                                    width={200}
                                    src="assets/images/youtue_img.png"
                                    alt="Video Illustration"
                                    className="img-fluid my-3"
                                />
                                        </div>
                                        <a href="#" className="btn btn-primary">Go somewhere</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="card mb-4 card-video">
                                    <div className="card-body" style={{padding:"20px"}}>
                                        <div className='d-flex'>
                               <div style={{ display: "flex", flexDirection: "column" }}>
                                            <h3 className="card-title" style={{ color: "#000", fontSize: "24px", fontWeight: "bold", textAlign: "center", margin: "10px 0" }}>
                                                Course 4
                                                
                                            </h3>
                                            <div className='d-flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <g clip-path="url(#clip0_43_1204)">
                                                        <path d="M15.5521 5.80296L10.6578 5.09181L8.46903 0.656838C8.27759 0.2689 7.72256 0.268713 7.53097 0.656838L5.34222 5.09181L0.448 5.80296C0.0199692 5.86518 -0.151937 6.39293 0.158125 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C16.1517 6.39315 15.9804 5.86524 15.5521 5.80296Z" fill="#F7AC16" />
                                                        <path d="M8.00003 0.36528C7.80053 0.36528 7.61891 0.478686 7.53094 0.656843L5.34219 5.09181L0.448 5.80297C0.0199692 5.86519 -0.151937 6.39294 0.158125 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717H8.00003V0.36528Z" fill="#F2D422" />
                                                        <path d="M0.448 5.80297C0.0199692 5.86519 -0.151937 6.39294 0.158125 6.69513L3.69959 10.1473L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.7293 15.6332 12.8373 15.5995 12.9284 15.5333L5.34219 5.09178L0.448 5.80297Z" fill="#F7AC16" />
                                                        <path d="M5.57994 9.54515C1.78638 5.84731 1.94944 6.0455 1.89853 5.85372C1.87781 5.77669 1.87356 5.68706 1.89609 5.59253L0.448 5.80294C0.0199692 5.86515 -0.151937 6.3929 0.158125 6.69509L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77713 14.7772 4.71331 14.5977 4.74388 14.4196L5.57994 9.54515Z" fill="#F48B19" />
                                                        <path d="M0.158124 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L0.0257181 6.15894C-0.0354381 6.37815 0.0257806 6.56619 0.158124 6.69512Z" fill="#F2D422" />
                                                        <path d="M0.158124 6.69512L3.69959 10.1472L2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77712 14.7772 4.71331 14.5977 4.74388 14.4196L5.57994 9.54515L3.14625 7.17287L0.0257181 6.15894C-0.0354382 6.37815 0.0257806 6.56619 0.158124 6.69512Z" fill="#EFB616" />
                                                        <path d="M2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C15.9845 6.55615 16.0357 6.34828 15.9743 6.15897L3.69959 10.1472L2.86353 15.0217Z" fill="#F7AC16" />
                                                        <path d="M2.86353 15.0217C2.82984 15.2179 2.9105 15.4162 3.07163 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77713 14.7772 4.71331 14.5977 4.74388 14.4196L5.57994 9.54515L5.57309 9.5385L3.69959 10.1472L2.86353 15.0217Z" fill="#F48B19" />
                                                        <path d="M3.62247 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C16.1516 6.39316 15.9804 5.86525 15.552 5.80297L10.6578 5.09181L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62247 15.5731Z" fill="#F2D422" />
                                                        <path d="M15.9743 6.15894L8 8.74994L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3004 10.1472L15.8419 6.69512C15.9845 6.55612 16.0358 6.34828 15.9743 6.15894Z" fill="#F7AC16" />
                                                        <path d="M12.3005 10.1473L8 8.74994L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.9433 15.6332 13.1915 15.3426 13.1365 15.0217L12.3005 10.1473Z" fill="#F2D422" />
                                                        <path d="M3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717C12.7658 15.7772 12.4385 15.6332 12.6209 15.6332C12.7293 15.6332 12.8373 15.5995 12.9284 15.5333L8 8.74994L3.07162 15.5333Z" fill="#F7AC16" />
                                                        <path d="M8.00003 8.75L8 8.74994L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L8 13.2717H8.00003V8.75Z" fill="#F2D422" />
                                                        <path d="M5.01059 12.8645L3.07162 15.5333C3.23269 15.6503 3.44619 15.6657 3.62244 15.5731L4.90953 14.8964C4.77712 14.7772 4.71331 14.5977 4.74387 14.4196L5.01059 12.8645Z" fill="#EFB616" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_43_1204">
                                                            <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p style={{ color: "black", marginLeft: "10px" }}>4.8</p>
                                            </div>
                                            <div className='d-flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <g clip-path="url(#clip0_43_1235)">
                                                        <path d="M14.5625 12.25H12.6875C12.4286 12.25 12.2188 12.0401 12.2188 11.7812V0.46875C12.2188 0.209875 12.4286 0 12.6875 0H14.5625C14.8214 0 15.0312 0.209875 15.0312 0.46875V11.7812C15.0312 12.0401 14.8214 12.25 14.5625 12.25Z" fill="#263238" />
                                                        <path d="M10.8125 12.25H8.9375C8.67862 12.25 8.46875 12.0401 8.46875 11.7812V2.40625C8.46875 2.14737 8.67862 1.9375 8.9375 1.9375H10.8125C11.0714 1.9375 11.2812 2.14737 11.2812 2.40625V11.7812C11.2812 12.0401 11.0714 12.25 10.8125 12.25Z" fill="#263238" />
                                                        <path d="M7.0625 12.25H5.1875C4.92862 12.25 4.71875 12.0401 4.71875 11.7812V4.28125C4.71875 4.02237 4.92862 3.8125 5.1875 3.8125H7.0625C7.32137 3.8125 7.53125 4.02237 7.53125 4.28125V11.7812C7.53125 12.0401 7.32137 12.25 7.0625 12.25Z" fill="#263238" />
                                                        <path d="M3.3125 12.25H1.4375C1.17862 12.25 0.96875 12.0401 0.96875 11.7812V6.15625C0.96875 5.89737 1.17862 5.6875 1.4375 5.6875H3.3125C3.57137 5.6875 3.78125 5.89737 3.78125 6.15625V11.7812C3.78125 12.0401 3.57137 12.25 3.3125 12.25Z" fill="#263238" />
                                                        <path d="M7.53125 14.5938C7.53125 14.7541 7.5475 14.9109 7.57844 15.0625H0.46875C0.21 15.0625 0 14.8525 0 14.5938C0 14.335 0.21 14.125 0.46875 14.125H7.57844C7.5475 14.2766 7.53125 14.4334 7.53125 14.5938Z" fill="#263238" />
                                                        <path d="M16 14.5938C16 14.8525 15.79 15.0625 15.5312 15.0625H12.1716C12.2025 14.9109 12.2188 14.7541 12.2188 14.5938C12.2188 14.4334 12.2025 14.2766 12.1716 14.125H15.5312C15.79 14.125 16 14.335 16 14.5938Z" fill="#263238" />
                                                        <path d="M9.875 16C9.09959 16 8.46875 15.3692 8.46875 14.5938C8.46875 13.8183 9.09959 13.1875 9.875 13.1875C10.6504 13.1875 11.2812 13.8183 11.2812 14.5938C11.2812 15.3692 10.6504 16 9.875 16Z" fill="#263238" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_43_1235">
                                                            <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p style={{ color: "black", marginLeft: "10px" }}>Beginner</p>
                                            </div>
                                            <div className='d-flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <g clip-path="url(#clip0_43_1253)">
                                                        <path d="M7.36606 4.08176C5.20075 4.08176 3.43916 5.84335 3.43916 8.00866C3.43916 10.174 5.20075 11.9356 7.36606 11.9356C9.53137 11.9356 11.293 10.174 11.293 8.00866C11.293 5.84335 9.53137 4.08176 7.36606 4.08176ZM7.36606 10.9981C5.71769 10.9981 4.37666 9.65707 4.37666 8.00869C4.37666 6.36032 5.71769 5.01929 7.36606 5.01929C9.01444 5.01929 10.3555 6.36032 10.3555 8.00869C10.3555 9.65707 9.01444 10.9981 7.36606 10.9981Z" fill="black" />
                                                        <path d="M15.8627 7.24441C15.6797 7.06138 15.3828 7.06138 15.1998 7.24441L14.7264 7.71779C14.6568 5.8561 13.8992 4.116 12.5746 2.79141C11.1833 1.40013 9.33356 0.633942 7.36603 0.633942C5.3985 0.633942 3.54875 1.40016 2.15747 2.79141C0.766188 4.18266 0 6.03247 0 8C0 9.96754 0.766219 11.8173 2.15747 13.2086C3.54872 14.5999 5.39853 15.3661 7.36606 15.3661C7.62494 15.3661 7.83481 15.1562 7.83481 14.8973C7.83481 14.6384 7.62494 14.4286 7.36606 14.4286C3.82134 14.4286 0.9375 11.5447 0.9375 8C0.9375 4.45529 3.82134 1.57144 7.36606 1.57144C10.8119 1.57144 13.6329 4.29675 13.7876 7.70507L13.3269 7.24441C13.1439 7.06138 12.8471 7.06138 12.664 7.24441C12.481 7.42744 12.481 7.72425 12.664 7.90732L13.9319 9.17519C14.0234 9.26672 14.1434 9.3125 14.2633 9.3125C14.3833 9.3125 14.5033 9.26672 14.5948 9.17519L15.8627 7.90732C16.0458 7.72429 16.0458 7.42747 15.8627 7.24441Z" fill="black" />
                                                        <path d="M7.83481 7.80584V6.625C7.83481 6.36612 7.62494 6.15625 7.36606 6.15625C7.10719 6.15625 6.89731 6.36612 6.89731 6.625V8C6.89731 8.12434 6.94669 8.24353 7.03462 8.33144L7.66409 8.96094C7.75562 9.0525 7.87559 9.09825 7.99553 9.09825C8.11547 9.09825 8.23547 9.05247 8.32697 8.96094C8.51 8.77791 8.51003 8.48112 8.32697 8.29803L7.83481 7.80584Z" fill="black" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_43_1253">
                                                            <rect width="16" height="16" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p style={{ color: "black", marginLeft: "10px" }}>6 Month</p>
                                            </div>
                                        </div>
                                        <img
                                    width={200}
                                    src="assets/images/youtue_img.png"
                                    alt="Video Illustration"
                                    className="img-fluid my-3"
                                />
                                        </div>
                                        <a href="#" className="btn btn-primary">Go somewhere</a>
                                    </div>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
})

export default ProfilePage;
