import { Modal, Button, Form, InputGroup, Image } from "react-bootstrap";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import adminStore from "../store/AdminStore";
import Sidebar from "../component/Sidebar";
import secureLocalStorage from "react-secure-storage";
import { io } from "socket.io-client";
import { jwtDecode } from "jwt-decode";
import { toJS } from "mobx";
import Loader from "../component/Loader";
import { BASE_FILE_URL } from "../api/config";

const LandingPage = observer(() => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [socket, setSocket] = useState(null);
  const [userData, setUserData] = useState({});
  const [courseData, setCourseData] = useState(null);

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const navigate = useNavigate();
  const isAuthenticated = secureLocalStorage.getItem("token");

  useEffect(() => {
    if (isAuthenticated) {
      const decoded = jwtDecode(isAuthenticated);
      setUserData(decoded);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    adminStore?.getActiveCourses();
  }, []);

  useEffect(() => {
    setCourseData(toJS(adminStore?.data?.courses));
  }, [adminStore?.data?.courses]);

  useEffect(() => {
    const token = secureLocalStorage.getItem("token");
    if (token) {
      const newSocket = io("http://192.168.29.49:3000", {
        query: { token },
      });
      setSocket(newSocket);

      newSocket.on("connect", () => {
        console.log("Connected to socket server");
      });

      return () => {
        newSocket.close();
      };
    }
  }, []);

  const handleCourseSelect = (id) => {
    navigate(`/youtube-page?id=${id}`);
  };

  const handleLogout = () => {
    localStorage.clear();

    try {
      socket.on("disconnect", () => {
        console.log("Disconnected from socket server");
      });
      socket.close();
    } catch (e) {
      console.log(e, "errrr");
    }
    navigate("/login");
  };



  return (
    <div className="port-view">
      <div className="d-flex bg-light main-card">
        <Sidebar />
        <div
          className="scrollable-section"
          style={{
            // maxHeight: "400px",  // Adjust this height as needed
            overflowY: "auto",
            marginTop: "19px",
            marginBottom: "19px",
            borderRadius: "10px",

            // backgroundColor: "#fff",
          }}
        >
          <div
            className="mx-auto card-x"
            style={{
              maxWidth: "92%",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <div className="d-flex cover-page">
              <div className="d-flex flex-column for-center">
                <h1> Learn. Earn. Grow with Crypto.</h1>
                <h2 style={{ color: "rgb(116 105 105)" }}>
                  New Rewards Await! Learn &amp; Earn with Every Step.
                </h2>
                <p style={{ color: "rgb(116 105 105)" }}>
                  Our Learn &amp; Earn courses are back with exciting new
                  rewards! Dive into interactive lessons, watch videos, take
                  quizzes, and earn Cashback Vouchers for each course you
                  complete. Each time you trade, use these vouchers to get a
                  portion of your fees back. Start learning, earning, and saving
                  today!
                </p>
                <button
                  className="btn btn-primary"
                  style={{ backgroundColor: "black" }}
                  onClick={() => {
                    if (isAuthenticated) {
                      // Scroll to the div with the specific ID
                      const targetDiv = document.getElementById("courses");
                      if (targetDiv) {
                        targetDiv.scrollIntoView({ behavior: "smooth" });
                      }
                    } else {
                      // Navigate to login page if not authenticated
                      navigate("/login");
                    }
                  }}
                >
                  Get Started
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src="assets/images/landing.png"
                  alt="cover-photo"
                  style={{
                    height: "450px",
                    marginLeft: "20px",
                    marginTop: "-47px",
                    marginBottom: "23px",
                  }}
                  className="start-pic"
                />
              </div>
            </div>

            {courseData &&
              courseData.map((item, index) => {
                const words = item.course_description.split(" ");
                const isLong = words.length > 9;

                return (
                  <div className="col-12 mb-4 mt-3" key={index} id="courses">
                    <div className="card h-100 shadow-sm d-flex mobile-flex">
                      <div
                        alt="Image of a city with Bitcoin symbols"
                        className="img-fluid image-size my-2 ms-2"
                        style={{
                          width: "310px",
                          height: "200px",
                          borderRadius: "28px",
                          backgroundImage: `url(${BASE_FILE_URL + "uploads/course_thumbnail/" + item?.thumbnail})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          boxShadow:"0px 0px 8px -2px",
                        }}
                      ></div>


                      <div className="card-body">
                        <h5 className="card-title">{item.course_name}</h5>
                        <p className="card-text">{isLong
                          ? words.slice(0, 6).join(" ") + "..."
                          : item.course_description}</p>
                        <button
                          className="btn btn-primary mt-3"
                          style={{ backgroundColor: "black" }}
                          onClick={() => handleCourseSelect(item.course_id)}
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>

      </div>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center mb-3">
            <Image
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3lqYismv8jTeVVEgSrAX78t107XY_ugOpCKCdsdpa6RoJPoztqAHSdriLx97zTTbTMOY&usqp=CAU"
              roundedCircle
              width="100"
              height="100"
            />
            <h5 className="ml-5" style={{ marginLeft: "20px" }}>
              {userData?.email}
            </h5>
          </div>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>
                Share some details about yourself with your riders.
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Wallet Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Your Wallet Address</Form.Label>
              <Form.Control
                type="text"
                value="24453213526352632632"
                readOnly
                onFocus={(e) => e.target.select()}
              />
            </Form.Group>
          </Form>
          <div className="wallet-connect mt-4">
            <h6>Connect Your Wallet</h6>
            <Button
              variant="primary"
              onClick={handleClose1}
              style={{ width: "100%" }}
            >
              Connect Wallet
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
      {adminStore.loading && <Loader />}
    </div>
  );
});
export default LandingPage;
