// Header.js
import { observer } from "mobx-react";
import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Socket } from "socket.io-client";
import Swal from "sweetalert2";

const AdminHeader = observer(() => {
  const navigate = useNavigate();
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out of your account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, log out",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: 'swal-confirm-red'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        try {
          Socket.on("disconnect", () => {
            console.log("Disconnected from socket server");
          });
          Socket.close();
        } catch (e) {
          console.log(e, "errrr");
        }
        navigate("/login", { replace: true });
      }
    });
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <img
          src="../assets/images/Logo.png"
          alt="Learn & Earn Logo"
          width="40" // Set width and height as needed
          height="40"
          style={{ marginRight: "-2px" }} // Space between logo and text
        />
        <Navbar.Brand>Learn & Earn</Navbar.Brand>
        <Navbar />
       
          <Nav className="ms-auto text-secondary">
            <Nav.Link onClick={handleLogout}  className="text-light">
              Logout
              <svg
                viewBox="0 0 512 512"
                width="16" // Set width and height as needed
                height="16"
                style={{ marginLeft: "5px" }} // Adds a small space between text and icon
              >
                <path
                  d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"
                  fill="currentColor" // Makes icon color match text color
                />
              </svg>
            </Nav.Link>
          </Nav>
      
      </Container>
    </Navbar>
  );
});

export default AdminHeader;
