import React from 'react';
import { Link } from 'react-router-dom';


function TimeSession() {
    return (
        <div style={{ width: "100%", backgroundColor: "rgb(70 101 123)", display: "flex", justifyItems: "center", justifyContent: "center", alignItems: "center" }}>
            <div className='d-flex bg-light main-card mt-5' >
                <div style={{ width: '10%', background: '#f0f0f0', padding: '10px' }}>
                <ul style={{ listStyleType: 'none', padding: 0, height: "100vh", margin: 0, display: 'flex', justifyContent: "space-between", justifyItems: "center", flexDirection: "column", alignItems:"center" }}>
                        <li style={{ margin: '10px 0' }}>
                            <Link to={"/profile"}><svg width={20}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                fill="currentColor" 
                            >
                                <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z" />
                            </svg>
                            </Link>
                        </li>
                        <li style={{ margin: '10px 0' }}>
                           <Link to={"/wallet"}> <svg width={20}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                fill="currentColor" 
                            >
                                <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L80 128c-8.8 0-16-7.2-16-16s7.2-16 16-16l368 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L64 32zM416 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                            </svg>
                            </Link>
                        </li>
                        <li style={{ margin: '10px 0' }}>
                           <Link to={"/time-session"} ><svg width={20}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 496 512"
                                fill="currentColor"
                            >
                                <path d="M247.6 8C389.4 8 496 118.1 496 256c0 147.1-118.5 248-248.4 248C113.6 504 0 394.5 0 256 0 123.1 104.7 8 247.6 8zm.8 44.7C130.2 52.7 44.7 150.6 44.7 256c0 109.8 91.2 202.8 203.7 202.8 103.2 0 202.8-81.1 202.8-202.8 .1-113.8-90.2-203.3-202.8-203.3zM137.7 221c13-83.9 80.5-95.7 108.9-95.7 99.8 0 127.5 82.5 127.5 134.2 0 63.6-41 132.9-128.9 132.9-38.9 0-99.1-20-109.4-97h62.5c1.5 30.1 19.6 45.2 54.5 45.2 23.3 0 58-18.2 58-82.8 0-82.5-49.1-80.6-56.7-80.6-33.1 0-51.7 14.6-55.8 43.8h18.2l-49.2 49.2-49-49.2h19.4z" />
                            </svg>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="card shadow-sm mx-auto" style={{ maxWidth: '50%', padding: '20px', borderRadius: '10px' }}>
                    {/* User Profile Section */}
                    <div className="d-flex align-items-center mb-3">
                        <img
                            src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                            alt="Profile"
                            className="rounded-circle"
                            width={50}
                        />
                        <div className="ms-2">
                            <h4 className="mb-0">Deepak Soni</h4>
                            <p className="text-muted mb-0">email@gmail.com</p>
                        </div>
                    </div>

                    {/* Logo Section */}
                    <div className="text-center mb-3">
                        <img
                            src="https://outreach.money/images/outreach-logo.png"
                            alt="Outreach Logo"
                            className="img-fluid"
                            width={300}
                        />
                        <p className="text-muted">facilitating fiat access to internet money...</p>
                    </div>

                    {/* Main Content */}
                    <div className="mb-4">
                        <h1 className="mb-3">Innovating the way you buy USDT.</h1>
                        <p>
                            Outreach Money is a groundbreaking project by Tawaka Fusion Tech Private Limited, dedicated to transforming the way people in India engage with cryptocurrencies. Our mission is clear: to simplify cryptocurrency transactions, making them secure, transparent, and accessible to everyone.
                        </p>
                        <p>
                            At Outreach Money, we understand that the world of digital assets can be complex and intimidating. That's why we've built a platform that prioritizes user experience, ensuring that even those new to crypto can navigate and utilize our services with ease. Our intuitive interface, coupled with cutting-edge technology, empowers users to trade stablecoins like USDT effortlessly and confidently.
                        </p>
                    </div>


                    <div className="text-center">
                        <button className="btn btn-outline-secondary d-flex align-items-center justify-content-center" style={{ width: "200px" }}>
                            <svg className="kOqhQd" width={60} aria-hidden="true" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0,0h40v40H0V0z"></path><g><path d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z" fill="#EA4335"></path><path d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z" fill="#FBBC04"></path><path d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z" fill="#4285F4"></path><path d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z" fill="#34A853"></path></g></svg>
                            Download on the Play store
                        </button>
                    </div>
                </div>
                <div className="container py-4 d-flex align-items-center" style={{ flexDirection: "column", justifyContent: "space-around" }}>
                    {/* YouTube Video Section */}
                    <div className="card  text-white mb-4" style={{backgroundColor:"rgb(196 212 228)"}}>
                        <div className="card-body text-center">
                            <h3 className="card-title">YOUTUBE VIDEO</h3>
                            <img
                            width={250}
                                src="https://imgs.search.brave.com/QmK6B1RXz0-NrXeezVovp_SeYnoPB92GgdXAig9NKzU/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/cGl4YWJheS5jb20v/cGhvdG8vMjAxMy8w/Ny8xMy8xMS8yNi9m/aWxtLTE1ODE1N182/NDAucG5n"
                                alt="Video Illustration"
                                className="img-fluid my-3"
                            />
                            <button className="btn btn-warning px-4 py-2">Try for free</button>
                        </div>
                    </div>

                    {/* Learn & Earn Section with Quizzes */}
                    <div className="d-flex justify-content-between align-items-center mb-4" style={{gap:"20px"}}>
                        <div className="position-relative">
                            <img
                                src="https://img.freepik.com/free-photo/yellow-blue-geomtric-cupboards_23-2148518480.jpg?t=st=1729243426~exp=1729247026~hmac=396dad3c50d71b481c260e9b860a7543d0d1674b5e0d8fba8f5b2dd181a25d47&w=360"
                                alt="Learn and Earn"
                                className="img-fluid"
                                width={200}
                                style={{borderRadius:"20px",  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}
                            />
                            <div className="position-absolute top-50 start-50 translate-middle text-dark" style={{ fontSize: '1.5rem', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                                Learn and Earn
                            </div>
                        </div>
                        <div className="w-75">
                            {/* Quiz 1 */}
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div className="d-flex align-items-center" style={{borderRadius:"50px", boxShadow:"1px 3px 5px #000", padding:"16px"}}>
                                    <img
                                        src="https://via.placeholder.com/40"
                                        alt="Quiz Icon"
                                        className="rounded-circle me-3"
                                    />
                                    <h5 className="mb-0">Quiz 1</h5>
                                </div>
                                <i className="bi bi-chevron-down fs-3"></i>
                            </div>

                            {/* Quiz 2 */}
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div className="d-flex align-items-center" style={{borderRadius:"50px", boxShadow:"1px 3px 5px #000", padding:"16px"}}>
                                    <img
                                        src="https://via.placeholder.com/40"
                                        alt="Quiz Icon"
                                        className="rounded-circle me-3"
                                    />
                                    <h5 className="mb-0">Quiz 2</h5>
                                </div>
                                <i className="bi bi-chevron-down fs-3"></i>
                            </div>

                            {/* Quiz 3 */}
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div className="d-flex align-items-center" style={{borderRadius:"50px", boxShadow:"1px 3px 5px #000", padding:"16px"}}>
                                    <img
                                        src="https://via.placeholder.com/40"
                                        alt="Quiz Icon"
                                        className="rounded-circle me-3"
                                    />
                                    <h5 className="mb-0">Quiz 3</h5>
                                </div>
                                <i className="bi bi-chevron-down fs-3"></i>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TimeSession;
