import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const AdminSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Toggle Button for Mobile */}
      <button
        className="btn btn-info d-md-none"
        onClick={toggleSidebar}
        style={{ position: "fixed", top: "5px", left: "5px", zIndex: 1000, width:"40px", height:"40px" }}
      >
        <i className="fas fa-bars"></i>
      </button>

      <div
        className={`sidebar p-3 bg-light ${isOpen ? "open" : ""} d-md-block`}
        style={{ transition: "transform 0.3s ease-in-out",height:"92.2vh" }}
      >
        <div className="d-flex align-items-center mb-4">
          <img
            src="../assets/images/admin.webp"
            alt="Admin Profile"
            className="rounded-circle me-3"
            width="50"
            height="50"
          />
          <div>
            <h5 className="mb-0">Learn & Earn</h5>
            <small className="text-muted">Admin Head</small>
          </div>
        </div>
        <nav className="nav flex-column">
          <NavLink to="/admin/dashboard" className="nav-link" activeClassName="active">
            <i className="fas fa-tachometer-alt"></i> <span>Dashboard</span>
          </NavLink>
          <NavLink to="/admin/project" className="nav-link" activeClassName="active">
            <i className="fas fa-th"></i> <span>Project</span>
          </NavLink>
       
          <NavLink to="/admin/show-topic" className="nav-link" activeClassName="active">
            <i className="fas fa-video"></i> <span>Topics</span>
          </NavLink>
          <NavLink to="/admin/quiz-sec" className="nav-link" activeClassName="active">
            <i className="fa-brands fa-quinscape"></i> <span>Quiz</span>
          </NavLink>
          <NavLink to="/admin/quiz" className="nav-link" activeClassName="active">
            <i className="fas fa-question-circle"></i> <span>Questions</span>
          </NavLink>
        
          <NavLink to="/admin/results" className="nav-link" activeClassName="active">
            <i className="fas fa-poll"></i> <span>Results</span>
          </NavLink>
          <NavLink to="/admin/users" className="nav-link" activeClassName="active">
            <i className="fas fa-users"></i> <span>Users</span>
          </NavLink>
        </nav>
      </div>
    </>
  );
};

export default AdminSidebar;
