import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import AdminSidebar from "../../component/AdminSidebar";
import AdminHeader from "../../component/AdminHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faChartLine,
  faProjectDiagram,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import adminStore from "../../store/AdminStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import Loader from "../../component/Loader";

const Dashboard = observer(() => {
  const [dashboardCount, setDashboardCount] = useState(null);

  useEffect(() => {
    adminStore?.getCount();
  }, []);

  useEffect(() => {
    setDashboardCount(toJS(adminStore?.data?.dashboardCount));
  }, [adminStore?.data?.dashboardCount]);

  return (
    <div>
    <AdminHeader />
    <div style={{ display: "flex", flexDirection: "row", height: "92.2vh", backgroundColor:"white" }}>
        <AdminSidebar />
        <div className="flex-grow-1" style={{
    flex: 1,
    overflow: "auto",
    padding: "30px",
  }}>
  <div className="container my-2">
    <h2 className="text-left mb-4">Dashboard</h2>
    <div className="table-responsive">
      {/* Check if data is passed and columns are properly defined */}
      <div className="container py-4">
              <div className="row g-5">
                <div className="col-md-6 col-lg-3">
                  <Link to="/admin/users" className="text-decoration-none">
                    <div
                      className="card bg-primary text-white shadow-lg border-0 rounded h-100"
                      style={{ transition: "transform 0.3s ease" }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.05)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    >
                      <div className="card-body text-center d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon={faUsers} className="mb-3 fs-1" />
                        <h5 className="card-title font-weight-bold">Users</h5>
                        <p className="h3 mb-3">{dashboardCount?.total_users}</p>
                        <p className="card-text">
                          Manage user accounts and permissions.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-md-6 col-lg-3">
                  <Link to="/admin/results" className="text-decoration-none">
                    <div
                      className="card bg-success text-white shadow-lg border-0 rounded h-100"
                      style={{ transition: "transform 0.3s ease" }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.05)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    >
                      <div className="card-body text-center d-flex flex-column align-items-center">
                      <i className="fas fa-poll fs-1 mb-3"></i> 
                        <h5 className="card-title font-weight-bold">
                          Number of Attempts
                        </h5>
                        <p className="h3 mb-3">
                          {dashboardCount?.total_attempts}
                        </p>
                        <p className="card-text">
                          View and analyze user results.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-md-6 col-lg-3">
                  <Link to="/admin/project" className="text-decoration-none">
                    <div
                      className="card bg-secondary text-white shadow-lg border-0 rounded h-100"
                      style={{ transition: "transform 0.3s ease" }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.05)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    >
                      <div className="card-body text-center d-flex flex-column align-items-center">
                      <i className="fas fa-th fs-1 mb-3"></i> 
                        <h5 className="card-title font-weight-bold">
                          Total Projects
                        </h5>
                        <p className="h3 mb-3">
                          {dashboardCount?.total_courses}
                        </p>
                        <p className="card-text">Overview of all projects.</p>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-md-6 col-lg-3">
                  <Link to="/admin/quiz-sec" className="text-decoration-none">
                    <div
                      className="card bg-warning text-white shadow-lg border-0 rounded h-100"
                      style={{ transition: "transform 0.3s ease" }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.05)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    >
                      <div className="card-body text-center d-flex flex-column align-items-center">
                      <i class="fa-brands fa-quinscape fs-1 mb-3"></i>
                        <h5 className="card-title font-weight-bold">
                          Total Quizzes
                        </h5>
                        <p className="h3 mb-3">
                          {dashboardCount?.total_quizzes}
                        </p>
                        <p className="card-text">Manage and create quizzes.</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
    </div>
  </div>
</div>

    </div>
</div>
  );
});

export default Dashboard;
