import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { Card, Button, Row, Col, Form as BootstrapForm } from 'react-bootstrap';
import { Formik, Field, FieldArray, Form } from 'formik';
import adminStore from "../../store/AdminStore";
import AdminSidebar from "../../component/AdminSidebar";
import AdminHeader from "../../component/AdminHeader";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";


const ProjectDetails = observer(() => {
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const courseId = queryParams.get("id");

  const navigate = useNavigate();
  const [thumbnailPreviews, setThumbnailPreviews] = useState({});

  const handleImageChange = (e, setFieldValue, index) => {
    const file = e.target.files[0];
    setFieldValue(`courses[${index}].video_thumbnail`, file);

    if (file) {
      setThumbnailPreviews((prev) => ({
        ...prev,
        [index]: URL.createObjectURL(file),
      }));
    } else {
      setThumbnailPreviews((prev) => {
        const updatedPreviews = { ...prev };
        delete updatedPreviews[index];
        return updatedPreviews;
      });
    }
  };


  const handleSaveCourses = async (values) => {
    const formData = new FormData();

    // Append the course ID
    formData.append("course_id", values.course_id);

    // Append each video data to the FormData object
    values.courses.forEach((course, index) => {
      formData.append(`videos[${index}][video_title]`, course.video_title);
      formData.append(`videos[${index}][video_description]`, course.video_description);
      formData.append(`videos[${index}][video_url]`, course.video_url);

      // Check if there's a thumbnail file before appending
      if (course.video_thumbnail) {
        formData.append(`videos[${index}][video_thumbnail]`, course.video_thumbnail);
      }
    });

    try {
      const response = await adminStore.addVideo(formData);

      if (response) {
        Swal.fire({
          title: "Success!",
          text: "Video has been successfully added.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/admin/project");
        });
      } else {
        Swal.fire({
          title: "Warning!",
          text: "Failed to add the video. Please try again.",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error adding video:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an issue adding the video.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }

  };

  const validationSchema = Yup.object({
    courses: Yup.array().of(
      Yup.object({
        video_title: Yup.string().required('Video title is required'),
        video_description: Yup.string().required('Video description is required'),
        video_url: Yup.string().required('Video URL is required'),
      })
    ).min(1, 'At least one topic is required'),
  });


  return (
    <div>
      <AdminHeader />
      <div style={{ display: "flex", flexDirection: "row", height: "fit-content", backgroundColor: "white" }}>
        <AdminSidebar />
        <div style={{ marginLeft: "20px", padding: "20px", width: "100%" }}>
          <h2>Add New Topic</h2>
          <Card className="my-3">
            <Card.Body>

              <Formik
                initialValues={{
                  courses: [{ video_title: '', video_description: '', video_url: '', video_thumbnail: null }],
                  course_id: courseId || ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSaveCourses}
              >
                {({ values, errors, setFieldValue }) => (
                  <Form>
                    <Row>
                      <FieldArray name="courses">
                        {({ push, remove }) => (
                          <>
                            {values.courses.map((course, index) => (
                              <Col md={4} key={index} className="mb-3">
                                <Card>
                                  <div className="d-flex justify-content-end align-items-end p-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="15"
                                      height="15"

                                      fontWeight={600}
                                      onClick={() => remove(index)}
                                      className="bg-danger"

                                    >
                                      <line
                                        x1="4"
                                        y1="4"
                                        x2="20"
                                        y2="20"
                                        stroke="white"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                      />
                                      <line
                                        x1="20"
                                        y1="4"
                                        x2="4"
                                        y2="20"
                                        stroke="white"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                      />
                                    </svg>
                                  </div>

                                  <Card.Body>
                                    <div className="mb-3">
                                      <label>Topic Name</label>
                                      <Field
                                        type="text"
                                        name={`courses[${index}].video_title`}
                                        placeholder="Enter topic name"
                                        className="form-control"
                                      />
                                      {errors.courses?.[index]?.video_title &&
                                        (
                                          <div className="text-danger">
                                            {errors.courses[index].video_title}
                                          </div>
                                        )}
                                    </div>

                                    <div className="mb-3">
                                      <label>Video Description</label>
                                      <Field
                                        as="textarea"
                                        rows={3}
                                        name={`courses[${index}].video_description`}
                                        placeholder="Enter topic description"
                                        className="form-control"
                                      />
                                      {errors.courses?.[index]?.video_description &&
                                        (
                                          <div className="text-danger">
                                            {errors.courses[index].video_description}
                                          </div>
                                        )}
                                    </div>

                                    <div className="mb-3">
                                      <label>Topic Video URL</label>
                                      <Field
                                        type="text"
                                        name={`courses[${index}].video_url`}
                                        placeholder="Enter video URL"
                                        className="form-control"
                                      />
                                      {errors.courses?.[index]?.video_url &&
                                        (
                                          <div className="text-danger">
                                            {errors.courses[index].video_url}
                                          </div>
                                        )}

                                    </div>

                                    <div className="mb-3">
                                      <label>Video Thumbnail</label>
                                      <input
                                        type="file"
                                        accept="image/*"
                                        className="form-control"
                                        // onChange={(e) =>
                                        //   setFieldValue(`courses[${index}].video_thumbnail`, e.target.files[0])
                                        // }
                                        onChange={(e) => handleImageChange(e, setFieldValue, index)}
                                      />
                                      {thumbnailPreviews[index] && (
                                        <div className="mt-3">
                                          <img
                                            src={thumbnailPreviews[index]}
                                            alt="Preview"
                                            style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                          />
                                        </div>
                                      )}
                                    </div>

                                    {/* <Button variant="danger" onClick={() => remove(index)}>
                                      Remove Course
                                    </Button> */}
                                  </Card.Body>
                                </Card>
                              </Col>
                            ))}

                            <button
                              type="button"
                              className="btn btn-primary"
                              style={{
                                position: "fixed",
                                bottom: "20px",
                                right: "20px",
                                width: "200px",
                                height: "40x",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "18px",
                              }}
                              onClick={() => {
                                push({ video_title: '', video_description: '', video_url: '', video_thumbnail: null });
                              }}
                            >
                              Add Another Topic
                            </button>
                          </>
                        )}
                      </FieldArray>
                    </Row>

                    <Button variant="success" type="submit" className="mt-3">
                      Save Projects
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
});

export default ProjectDetails;
