import logo from './logo.svg';
import './App.css';

import { Route, Routes, BrowserRouter } from "react-router-dom"
import OutreachPage from './views/OutreachPage';
import ProfilePage from './views/ProfilePage';
import WalletPage from './views/WalletPage';
import TimeSession from './views/TimeSession';
import YoutubePage from './views/YoutubePage';
import Login from './views/Login';
import PrivateRoute from './middleware/PrivateRoute';
import LandingPage from './views/LandingPage';
import AdminPage from './views/AdminPage';
import QuizPage from './views/QuizPage';
import CourseTable from './views/admin/Course';
import Course from './views/admin/Course';
import Dashboard from './views/admin/Dashboard';
import QuizResult from './views/admin/QuizResult';
import User from './views/admin/User';
import ProjectDetails from './views/admin/ProjectDetails';
import Results from './views/admin/Results';
import OtpVerification from './views/OtpVerification';
import ResetPassword from './views/ResetPassword';
import AddQuizPage from './views/admin/AddQuizPage';
import EditTopic from './views/admin/EditTopic';
import ShowTopics from './views/admin/ShowTopics';
import EditQuiz from './views/admin/EditQuiz';
import AdminRoute from './middleware/AdminRoute';
import QuizSection from './views/admin/QuizSection';
import AddQuizQuestions from './views/admin/AddQuizQuestions';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/' element={<LandingPage />} />
        <Route exact path='/otp-verification' element={<OtpVerification />} />
        <Route exact path='/reset-password' element={<ResetPassword />} />

        <Route element={<PrivateRoute />}>
          {/* <Route exact path='/' element={<OutreachPage/>}/> */}
          <Route exact path='/profile' element={<ProfilePage />} />
          <Route exact path='/wallet' element={<WalletPage />} />
          <Route exact path='/time-session' element={<TimeSession />} />
          <Route exact path='/youtube-page' element={<YoutubePage />} />
          <Route exact path='/quiz-page' element={<QuizPage />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route exact path='/admin/project' element={<Course />} />
          <Route exact path='/admin/show-topic' element={<ShowTopics />} />
          <Route exact path='/admin/edit-topic' element={<EditTopic />} />
          <Route exact path='/admin/edit-quiz' element={<EditQuiz />} />
          <Route exact path='/admin/dashboard' element={<Dashboard />} />
          <Route exact path='/admin/quiz' element={<QuizResult />} />
          <Route exact path='/admin/users' element={<User />} />
          <Route path="/admin/topic" element={<ProjectDetails />} />
          <Route path="/admin/quiz-sec" element={<QuizSection />} />
          <Route exact path='/admin/results' element={<Results />} />
          <Route exact path='/admin/addquiz' element={<AddQuizPage />} />
          <Route exact path='/admin/addquiz-questions' element={<AddQuizQuestions />} />
        </Route>
        <Route exact path='/admin-page' element={<AdminPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
