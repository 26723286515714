import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import adminStore from "../store/AdminStore";

const OtpVerification = () => {
  const navigate = useNavigate();
  const otpFormRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const emailId = queryParams.get("email");

  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timer, setTimer] = useState(60);

  // Timer logic
  useEffect(() => {
    let countdown;
    if (isTimerActive) {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown); // Clear interval when timer reaches 0
            setIsTimerActive(false); // Reset the timer state
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(countdown); // Cleanup on component unmount or timer reset
  }, [isTimerActive]);

  const handleOtpSubmit = async (values) => {
    try {
      const creds = {
        email: emailId,
        otp: values.otp,
      };
      const response = await adminStore?.verifyOtp(
        creds,
        navigationCallBackVerify
      );
    } catch (error) {
      console.error("OTP verification failed:", error);
      toast.error("OTP verification failed.");
    }
  };

  const handleResendOtp = async () => {
    if (isTimerActive) return;

    try {
      const creds = { email: emailId };
      const response = await adminStore?.resendOtp(
        creds,
        navigationCallBackResendOtp
      );
      setIsTimerActive(true); // Activate the timer
      setTimer(60); // Reset the timer to 60 seconds
    } catch (error) {
      console.error("Resending OTP failed:", error);
      toast.error("Resending OTP failed. Please try again.");
    }
  };

  const navigationCallBackVerify = () => {
    toast.success("Registration Successful");
    navigate(`/login`);
  };

  const navigationCallBackResendOtp = () => {
    toast.success("OTP Sent Successfully");
  };

  const otpValidationSchema = Yup.object().shape({
    otp: Yup.string()
      .length(6, "OTP must be exactly 6 digits")
      .matches(/^[0-9]+$/, "OTP must contain only numbers")
      .required("Required"),
  });

  return (
    <div className="port-view">
      <div className="wrapper">
        <div className="title-text">
          <div className="title">OTP Verification</div>
        </div>
        <div className="form-container">
          <div className="form-inner">
            <Formik
              initialValues={{ email: "", otp: "" }}
              validationSchema={otpValidationSchema}
              onSubmit={handleOtpSubmit}
              innerRef={otpFormRef}
              autoComplete="off"
              enableReinitialize
            >
              {() => (
                <Form className="otp-verification">
                  <div className="field">
                    <Field
                      type="text"
                      name="otp"
                      placeholder="Enter OTP"
                      maxLength="6"
                      autoComplete="one-time-code"
                    />
                    <ErrorMessage
                      name="otp"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                  <div className="field">
                    <input type="submit" value="Verify OTP" />
                  </div>
                  <div className="pass-link" style={{textAlign:"center"}}>
                    Didn't receive an OTP?
                    <br />
                    {isTimerActive ? (
                      <span>Resend OTP in {timer}s</span>
                    ) : (
                      <a href="#" onClick={handleResendOtp }>
                        Resend OTP
                      </a>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
