import React, { Fragment, useEffect, useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import adminStore from "../../store/AdminStore";
import AdminSidebar from "../../component/AdminSidebar";
import AdminHeader from "../../component/AdminHeader";
import Loader from "../../component/Loader";
import { Modal, Button, Form } from "react-bootstrap";
import swal from "sweetalert";

const User = observer(() => {
  const [courseData, setCourseData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    wallet_address: "",
    ip_address: "",
  });

  // Fetch users on component mount
  useEffect(() => {
    adminStore.getUsers();
  }, []);

  // Update courseData when adminStore.data.users changes
  useEffect(() => {
    if (adminStore.data.users) {
      setCourseData(toJS(adminStore.data.users));
    }
  }, [adminStore.data.users]);

  // Handle opening the edit modal and setting selected user
  const handleEditUser = (user) => {
    setSelectedUser(user);
    setFormData({
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      email: user.email || "",
      wallet_address: user.wallet_address || "",
      ip_address: user.ip_address || "",
    });
    setShowEditModal(true);
    console.log(user, "kdsjfnhdskj");
  };

  // Handle closing the edit modal
  const handleCloseModal = () => {
    setShowEditModal(false);
    setSelectedUser(null);
  };

  // Handle saving changes (you can add API call logic here)
  const handleSaveChanges = () => {
    if (selectedUser) {
      const updatedUser = { ...selectedUser, ...formData };
      console.log("Updated User:", updatedUser);

      adminStore.updateUser(updatedUser).then(() => {
        adminStore.getUsers();
        handleCloseModal();
      });
    }
  };

  // const handleDeleteUser = (record) => {
  //   adminStore?.deleteUser(record?.id, () => adminStore.getUsers());
  // };
  const handleDeleteUser = (record) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this User!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "btn btn-secondary",
          closeModal: true,
        },
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "btn btn-danger",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (record?.id) {
          adminStore
            ?.deleteUser(record?.id, navigationCallBackDelete)
            .catch((error) => {
              console.error("Error deleting User:", error);
              swal(
                "Error!",
                "Failed to delete the User. Please try again.",
                "error"
              );
            });
        }
      } else {
        swal("Cancelled", "The User is not deleted", "info");
      }
    });
  };
  const navigationCallBackDelete = () => {
    adminStore.getUsers();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Define columns for ReactDatatable
  const columns = [
    {
      key: "first_name",
      text: "First Name",
      align: "left",
      sortable: true,
    },
    {
      key: "last_name",
      text: "Last Name",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      align: "left",
      sortable: true,
    },
    {
      key: "wallet_address",
      text: "Wallet Address",
      align: "left",
      sortable: true,
    },
    {
      key: "ip_address",
      text: "IP Address",
      align: "left",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      width: 100,
      align: "center",
      sortable: false,
      cell: (record) => (
        <Fragment>
          <button
            className="btn btn-primary btn-sm mb-2"
            onClick={() => handleEditUser(record)}
            style={{ marginRight: "5px", width: "70px" }}
          >
            <i className="fa-solid fa-pen mx-2"></i>Edit
          </button>
          <button
            className="btn btn-danger btn-sm mb-2"
            onClick={() => handleDeleteUser(record)}
            style={{ marginRight: "5px", width: "70px" }}
          >
            <i className="fa-solid fa-trash mx-2"></i>Delete
          </button>
        </Fragment>
      ),
    },
  ];

  // Configuration for ReactDatatable
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Users",
    no_data_text: "No users found!",
    button: {
      excel: true,
    },
  };

  return (
    <div>
      <AdminHeader />
      <div style={{ display: "flex", flexDirection: "row", height: "92.2vh",backgroundColor:"white" }}>
        <AdminSidebar />
        <div
          style={{
            flex: 1,
            overflow: "auto",
            backgroundColor: "#fff",
            padding: "30px",
          }}
        >
          <h2 style={{ textAlign: "left", marginBottom: "20px" }}>Users</h2>
          <ReactDatatable
            config={config}
            records={courseData}
            columns={columns}
          />
        </div>
      </div>
      {adminStore.loading && <Loader />}

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="first_name">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="last_name" className="mt-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="email" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                readOnly
              />
            </Form.Group>
            {/* <Form.Group controlId="wallet_address" className="mt-3">
              <Form.Label>Wallet Address</Form.Label>
              <Form.Control
                type="text"
                name="wallet_address"
                value={formData.wallet_address}
                onChange={handleInputChange}
              />
            </Form.Group> */}
            <Form.Group controlId="ip_address" className="mt-3">
              <Form.Label>IP Address</Form.Label>
              <Form.Control
                type="text"
                name="ip_address"
                value={formData.ip_address}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default User;
