import React, { Fragment, useEffect, useState } from "react";
import { observer } from "mobx-react";
import adminStore from "../../store/AdminStore";
import AdminSidebar from "../../component/AdminSidebar";
import AdminHeader from "../../component/AdminHeader";
import { Button, Card, Modal } from "react-bootstrap";
import ReactDatatable from "@mkikets/react-datatable";
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";
import Loader from "../../component/Loader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import Select from "react-select";

const QuizSection = observer(() => {
  const [quizData, setQuizData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState(null);
  const [selectedOption, setSelectedOption] = useState([]);
  const [courseData, setCourseData] = useState([]);

  useEffect(()=>{
    setCourseData(toJS(adminStore?.data?.courses))
  },[adminStore?.data?.courses])

  useEffect(() => {
    adminStore?.getAllQuiz();
  }, []);

  useEffect(() => {
    setQuizData(toJS(adminStore?.data?.all_Quiz));
  }, [adminStore?.data?.all_Quiz]);

  const navigate = useNavigate();

  const handleEditQuiz = (record) => {
    setCurrentQuiz(record);
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
    setCurrentQuiz(null);
  };

  
  const handleChange = (selected) => {
    setSelectedOption(selected);

    if (selected && selected.value) {
        const filteredVideos = toJS(adminStore?.data?.all_Quiz).filter(
            (video) => video.course_id === selected.value
        );
        setQuizData(filteredVideos);
    } else {
      setQuizData(toJS(adminStore?.data?.all_Quiz));
    }
};

    
  const handleDeletQuiz = (record) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Quiz!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "btn btn-secondary",
          closeModal: true,
        },
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "btn btn-danger",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if(record?.quiz_id){
            adminStore?.deleteQuizSec(record?.quiz_id ,navigationCallBackDelete)
              .catch((error) => {
                console.error("Error deleting Quiz:", error);
                swal("Error!", "Failed to delete the Quiz. Please try again.", "error");
              });
        }
       
         
      } else {
        swal("Cancelled", "The Quiz is not deleted", "info");
      }
    });
  };

  const navigationCallBackDelete = () =>{
    adminStore.getAllQuiz();
  } 

  const handleSubmit = (values) => {
    adminStore.updateQuizSec(values)
      .then(() => {
        adminStore.getAllQuiz(); // Refresh the list
        handleCloseModal();
      })
      .catch((err) => {
        console.error("Error updating quiz:", err);
        swal("Error", "Failed to update the quiz", "error");
      });
  };

  const handleAddQuestion =(record)=>{
    navigate(`/admin/addquiz-questions?id=${record?.quiz_id}`)
  }

  const validationSchema = Yup.object().shape({
    quiz_name: Yup.string().required("Quiz name is required"),
    quiz_description: Yup.string().required("Description is required"),
  });
  const options = Array.isArray(courseData)
    ? courseData.map((course) => ({
      value: course?.course_id,
      label: course?.course_name,
    }))
    : [];

  const columns = [
    {
      key: "#",
      text: "S No.",
      align: "left",
      sortable: true,
      cell: (record, ind) => <span>{ind + 1}</span>,
    },
    { key: "course_name", text: "Project name", align: "left", sortable: true },
    { key: "quiz_name", text: "Quiz name", align: "left", sortable: true },
    { key: "quiz_description", text: "Quiz description", align: "left", sortable: true },
    {
      key: "action",
      text: "Action",
      align: "left",
      cell: (record) => (
        <Fragment>
          <button
            className="btn btn-primary btn-sm mb-2"
            onClick={() => handleEditQuiz(record)}
            style={{ marginRight: "5px", width: "70px" }}
          >
            <i className="fa-solid fa-pen mx-2"></i>Edit
          </button>
          <button
            className="btn btn-danger btn-sm mb-2"
            onClick={() => handleDeletQuiz(record)}
            style={{ marginRight: "5px", width: "70px" }}
          >
            <i className="fa-solid fa-trash mx-2"></i>Delete
          </button>
          <button
            className="btn btn-info btn-sm mb-2"
            onClick={() => handleAddQuestion(record)}
            style={{ marginRight: "5px", width: "150px" }}
          >
            <i className="fa-solid fa-add mx-2"></i>Add Question
          </button>
        </Fragment>
      ),
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Projects",
    no_data_text: "No Projects found!",
    button: { excel: true },
  };

  return (
    <div>
      <AdminHeader />
      <div style={{ display: 'flex', flexDirection: 'row', height: '92.2vh',backgroundColor:"white" }}>
        <AdminSidebar />
        <div style={{ flex: 1, overflow: 'auto', backgroundColor: "#fff", padding: "30px" }}>
        <div className='d-flex justify-content-between'>
            <h2 className=" mb-4">Quiz List</h2>
            <div>
            <p className="text-center fs-5 ">Select Project</p>
            <Select
                options={options}
                value={selectedOption}
                onChange={handleChange}
                placeholder="Select Project"
                styles={{
                  container: (base) => ({ ...base, width: "200px", marginTop: "10px" }),
                }}
                isMulti
              />
            </div>
            </div>
          <ReactDatatable config={config} records={quizData} columns={columns} />
        </div>
      </div>
      {adminStore.loading && <Loader />}
      {/* Edit Quiz Modal */}
      <Modal show={showEditModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Quiz</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            quiz_id: currentQuiz?.quiz_id || "",
            quiz_name: currentQuiz?.quiz_name || "",
            quiz_description: currentQuiz?.quiz_description || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Modal.Body>
                <div className="mb-3">
                  <label>Quiz Name</label>
                  <Field name="quiz_name" className="form-control" />
                  <ErrorMessage name="quiz_name" component="div" className="text-danger" />
                </div>
                <div className="mb-3">
                  <label>Description</label>
                  <Field name="quiz_description" as="textarea" className="form-control" />
                  <ErrorMessage name="quiz_description" component="div" className="text-danger" />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
});

export default QuizSection;
