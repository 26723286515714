import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Sidebar from "../component/Sidebar";
import { Button, Card, Form, ListGroup, Modal } from "react-bootstrap";
import adminStore from "../store/AdminStore";
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import secureLocalStorage from "react-secure-storage";
import Confetti from "react-confetti";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const QuizPage = observer(() => {
  const [questionData, setQuestionData] = useState([]);
  const [attemptId, setAttemptId] = useState(null);
  const [answerId, setAnswerId] = useState(null);
  const [quizId, setQuizId] = useState(null);
  const [score, setScore] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userData, setUserData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState({ title: "", message: "" });
  const [showConfetti, setShowConfetti] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [walletError, setWalletError] = useState("");
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const bep20Regex = /^0x[a-fA-F0-9]{40}$/;
  const trc20Regex = /^T[a-zA-Z0-9]{33}$/;
  const erc20Regex = /^0x[a-fA-F0-9]{40}$/;
  const decodeCourseId = (encodedCourseId) => {
    try {
      return atob(encodedCourseId);
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const cid = decodeCourseId(queryParams.get("course_id"));
    if (!cid) {
      navigate("/");
      toast.error("Don't try to be smart");
      return;
    }
    adminStore.getQuiz(cid);
    const isAuthenticated = secureLocalStorage.getItem("token");
    if (isAuthenticated) {
      const decoded = jwtDecode(isAuthenticated);
      setUserData(decoded);
    }
  }, [navigate]);

  useEffect(() => {
    const data = toJS(adminStore?.data?.quizes?.questions);
    const quizId = toJS(adminStore?.data?.quizes?.quiz_id);
    setQuizId(quizId);
    if (quizId && userData) {
      const param = {
        user_id: userData?.id,
        quiz_id: quizId,
      };
      if (param?.user_id && param?.quiz_id) {
        adminStore?.startQuiz(param)?.then((response) => {
          setAttemptId(response);
        });
      }
    }

    if (data) {
      // Map the questions and answers into the desired format
      const questions = data?.map((item) => ({
        id: item.question_id,
        text: item.question_text,
        answers: item.answers.map((answer) => ({
          id: answer.answer_id,
          text: answer.answer_text,
          isCorrect: answer.is_correct,
        })),
      }));
      setQuestionData(questions);
    }
  }, [adminStore?.data?.quizes]);

  useEffect(() => {
    const isReloaded = sessionStorage.getItem("isReloaded");
    if (isReloaded) {
      navigate("/");
    } else {
      sessionStorage.setItem("isReloaded", "true");
    }
    return () => {
      sessionStorage.removeItem("isReloaded");
    };
  }, [navigate]);

  const handleAnswerSelect = (questionId, answerId) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: answerId,
    }));
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questionData?.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    }
  };

  const handleSubmitQuiz = async () => {
    const answers = Object.keys(selectedAnswers).map((questionId) => ({
      question_id: questionId,
      answer_id: selectedAnswers[questionId],
    }));

    const payload = {
      user_id: userData?.id,
      quiz_id: quizId,
      attempt_id: attemptId,
      answers: answers,
    };

    try {
      const response = await adminStore?.submitQuizAnswer(payload);
      if (response) {
        const score = response?.score;
        setAnswerId(response?.result_id);
        setScore(score);
        let modalMessage = "";
        let modalTitle = "";
        let modalMessage2 = "";

        // Check score and set modal message accordingly
        if (score >= 80) {
          modalTitle = "🎉 Congratulations! 🎉";
          modalMessage = `You have successfully completed the course with the score of ${score} % As a token of appreciation, you will receive 1 USDT. To claim your reward, please add your wallet address here:`;
          modalMessage2 = `Thank you for your dedication and hard work. We wish you success in your future learning journey!`;
          setShowConfetti(true);
        } else {
          modalTitle = "Improve Yourself!";
          modalMessage = `You scored ${score}%. Keep practicing, you’ll get there!`;
          modalMessage2 = ``;
        }
        setModalData({
          title: modalTitle,
          message: modalMessage,
          modalMessage2: modalMessage2,
        });
        setModalVisible(true); 
      } else {
        toast.error("Failed to submit quiz. Please try again.");
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while submitting the quiz.");
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setModalData({ title: "", message: "", modalMessage2: "" });
    setShowConfetti(false);
    navigate(`/`);
  };

  const handleWalletSubmit = async () => {
    try {
      const param = {
        id: userData?.id,
        wallet_address: walletAddress,
        result_id: answerId,
      };
      await adminStore?.submitWalletAddress(param, navigationCallBackWallet);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Failed to Send Reward",
        text: "An error occurred while sending your reward. Please try again.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        background: "#f44336",
        color: "white",
      });
    }
  };

  const navigationCallBackWallet = (msg) => {
    Swal.fire({
      icon: "success",
      title: msg,
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      background: "#4CAF50",
      color: "white",
    });

    setModalVisible(false);
    setModalData({ title: "", message: "", modalMessage2: "" });
    setShowConfetti(false);
    navigate(`/`);
  };

  const handleAddressChange = (e) => {
    const inputValue = e.target.value;
    setWalletAddress(inputValue);

    if (
      !bep20Regex.test(inputValue) &&
      !trc20Regex.test(inputValue) &&
      !erc20Regex.test(inputValue)
    ) {
      setWalletError(
        "Invalid wallet address. Please enter a valid wallet address."
      );
    } else {
      setWalletError("");
    }
  };

  const allQuestionsAnswered =
    questionData?.length === Object.keys(selectedAnswers).length;

  return (
    <div className="port-view">
      <div className="d-flex bg-light main-card">
        <Sidebar />

        <div
          className="scrollable-section w-100"
          style={{
            overflowY: "auto",
            marginTop: "19px",
            marginBottom: "19px",
            borderRadius: "10px",
          }}
        >
          <div
            className="mx-auto card-x"
            style={{
              maxWidth: "92%",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <div className="d-flex cover-page">
              <div className="d-flex flex-column for-center">
                <h1> Learn. Earn. Grow with Crypto.</h1>
                <h2 style={{ color: "rgb(116 105 105)" }}>
                  Fun, Engaging Quizzes with Exciting Rewards!
                </h2>
                <p style={{ color: "rgb(116 105 105)" }}>
                  Dive into our interactive quiz platform and challenge yourself
                  with fun, knowledge-packed quizzes. Earn exclusive rewards for
                  every quiz you complete successfully. Sharpen your skills,
                  explore new topics, and make every correct answer count
                  towards your growth.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src="assets/images/landing.png"
                  alt="quiz-photo"
                  style={{
                    height: "345px",
                    marginLeft: "20px",
                    marginTop: "-47px",
                    marginBottom: "23px",
                  }}
                  className="quiz-pic"
                />
              </div>
            </div>

            {questionData?.length > 0 && (
              <Card
                style={{
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Card.Body>
                  <Card.Title
                    as="h5"
                    style={{
                      fontWeight: "bold",
                      color: "#34495E",
                      marginBottom: "1.5em",
                    }}
                  >
                    {currentQuestionIndex + 1}.{" "}
                    {questionData[currentQuestionIndex]?.text + " ?"}
                  </Card.Title>
                  <ListGroup
                    as="ul"
                    style={{ marginTop: "0.5em", width: "100%" }}
                  >
                    {questionData[currentQuestionIndex]?.answers.map(
                      (answer) => (
                        <ListGroup.Item
                          as="li"
                          key={answer.id}
                          active={
                            selectedAnswers[
                              questionData[currentQuestionIndex]?.id
                            ] === answer.id
                          }
                          onClick={() => {
                            if (
                              !selectedAnswers[
                                questionData[currentQuestionIndex]?.id
                              ]
                            ) {
                              handleAnswerSelect(
                                questionData[currentQuestionIndex]?.id,
                                answer.id
                              );
                            }
                          }}
                          style={{
                            cursor: selectedAnswers[
                              questionData[currentQuestionIndex]?.id
                            ]
                              ? "not-allowed"
                              : "pointer",
                            backgroundColor:
                              selectedAnswers[
                                questionData[currentQuestionIndex]?.id
                              ] === answer.id
                                ? "#1ABC9C"
                                : "#ECF0F1",
                            color:
                              selectedAnswers[
                                questionData[currentQuestionIndex]?.id
                              ] === answer.id
                                ? "white"
                                : "#34495E",
                            borderRadius: "8px",
                            marginBottom: "0.5em",
                            padding: "10px 15px",
                            fontWeight:
                              selectedAnswers[
                                questionData[currentQuestionIndex]?.id
                              ] === answer.id
                                ? "bold"
                                : "normal",
                            transition: "background-color 0.3s",
                            width: "100%",
                          }}
                          disabled={
                            selectedAnswers[
                              questionData[currentQuestionIndex]?.id
                            ]
                          }
                        >
                          {answer.text}
                        </ListGroup.Item>
                      )
                    )}
                  </ListGroup>
                  <div
                    style={{
                      marginTop: "1em",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    {currentQuestionIndex === questionData?.length - 1 ? (
                      <Button
                        variant="primary"
                        onClick={handleSubmitQuiz}
                        disabled={!allQuestionsAnswered}
                        style={{
                          backgroundColor: allQuestionsAnswered
                            ? "#3498DB"
                            : "#B3B3B3",
                          border: "none",
                        }}
                      >
                        Submit Quiz
                      </Button>
                    ) : (
                      <Button
                        variant="secondary"
                        onClick={handleNextQuestion}
                        disabled={
                          selectedAnswers[
                            questionData[currentQuestionIndex]?.id
                          ] === undefined
                        }
                      >
                        Next
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            )}
          </div>
        </div>
      </div>
      {showConfetti && <Confetti />}
      <Modal
        show={modalVisible}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="modal-header1">
          <Modal.Title>{modalData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "justify" }}>
          {modalData?.message}
          {score >= 80 && (
            <Form>
              <Form.Group controlId="walletAddress" className="w-100 my-4">
                <Form.Control
                  type="text"
                  placeholder="Enter Your Wallet Address"
                  value={walletAddress}
                  onChange={handleAddressChange}
                  isInvalid={!!walletError}
                />
                <Form.Control.Feedback type="invalid">
                  {walletError}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          )}
          {modalData?.modalMessage2}
        </Modal.Body>
        <Modal.Footer className="modal-footer-quiz">
          {score >= 80 ? (
            <Button
              variant="primary"
              onClick={handleWalletSubmit}
              disabled={!!walletError || !walletAddress}
            >
              Submit
            </Button>
          ) : (
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default QuizPage;
