import React from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import adminStore from '../../store/AdminStore'; // Ensure this is correctly configured
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import AdminHeader from '../../component/AdminHeader';
import AdminSidebar from '../../component/AdminSidebar';
import { Col, Row } from 'react-bootstrap';

// Validation Schema
const validationSchema = Yup.object({
    quiz_name: Yup.string().required('Quiz name is required'),
    quiz_description: Yup.string().required('Quiz description is required'),
    questions: Yup.array().of(
        Yup.object({
            question_text: Yup.string().required('Question text is required'),
            options: Yup.array()
                .of(Yup.string().required('Option is required'))
                .length(4, 'There must be exactly four options'),
            answers: Yup.string().required('Select a correct answer'),
        })
    ).min(1, 'At least one question is required'),
});

const AddQuizPage = () => {

    const queryParams = new URLSearchParams(window.location.search);
    const courseId = queryParams.get("id");

    const initialValues = {
        course_id: courseId || '',
        quiz_name: '',
        quiz_description: '',
        questions: [
            {
                question_text: '',
                options: ['', '', '', ''],
                answers: '',
            },
        ],
    };

    const handleSubmit = async (values, { resetForm }) => {
        try {
            // Call the addQuiz method and await its result
            const response = await adminStore?.addQuiz(values);
    
            if (response) {
                // Success Alert
                Swal.fire({
                    icon: 'success',
                    title: 'Quiz Added Successfully!',
                    text: `Quiz "${values.quiz_name}" has been added.`,
                    confirmButtonText: 'OK',
                });
    
                resetForm();
            } else {
        
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to Add Quiz',
                    text: response?.message || 'An error occurred while adding the quiz. Please try again.',
                    confirmButtonText: 'OK',
                });
            }
        } catch (error) {
            // Error Alert for unexpected issues (e.g., network problems)
            Swal.fire({
                icon: 'error',
                title: 'Failed to Add Quiz',
                text: 'An error occurred while adding the quiz. Please try again.',
                confirmButtonText: 'OK',
            });
        }
    };
    

    return (
        <div>
            <AdminHeader />
            <div style={{ display: 'flex', flexDirection: 'row', height: 'fit-content' , backgroundColor:"white" }}>
                <AdminSidebar />
                <div className="container mt-4">
                    <h1 className="text-left mb-4">Add Quiz</h1>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched }) => (
                            <Form>
                                {/* Quiz Name */}
                                <div className="mb-3">
                                    <label className="form-label">Quiz Name</label>
                                    <Field
                                        name="quiz_name"
                                        placeholder="Enter quiz name"
                                        className="form-control"
                                    />
                                    {errors.quiz_name && touched.quiz_name && (
                                        <div className="text-danger">{errors.quiz_name}</div>
                                    )}
                                </div>

                                {/* Quiz Description */}
                                <div className="mb-3">
                                    <label className="form-label">Quiz Description</label>
                                    <Field
                                        as="textarea"
                                        name="quiz_description"
                                        placeholder="Enter quiz description"
                                        className="form-control"
                                    />
                                    {errors.quiz_description && touched.quiz_description && (
                                        <div className="text-danger">{errors.quiz_description}</div>
                                    )}
                                </div>
                                <Row>
                                    {/* Questions */}
                                    <FieldArray name="questions">
                                        {({ push, remove }) => (
                                            <>
                                                {values.questions.map((question, qIndex) => (
                                                    <Col md={4} key={qIndex} className="mb-3 shadow">
                                                        <div className="card-body">
                                                            <div className='d-flex justify-content-end align-items-end p-2'>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                width="15"
                                                                height="15"
                                                                fill="white"
                                                                fontWeight={600}
                                                                right="0"
                                                                onClick={() => remove(qIndex)}
                                                                className='bg-danger'
                                                                
                                                            >
                                                                <line
                                                                    x1="4"
                                                                    y1="4"
                                                                    x2="20"
                                                                    y2="20"
                                                                    stroke="white"
                                                                    strokeWidth="3"
                                                                    strokeLinecap="round"
                                                                />
                                                                <line
                                                                    x1="20"
                                                                    y1="4"
                                                                    x2="4"
                                                                    y2="20"
                                                                    stroke="white"
                                                                    strokeWidth="3"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                            </div>
                                                            
                                                            <h5 className="card-title">Question {qIndex + 1}</h5>

                                                            {/* Question Text */}
                                                            <div className="mb-3">
                                                                <label className="form-label">Question Text</label>
                                                                <Field
                                                                    name={`questions.${qIndex}.question_text`}
                                                                    placeholder="Enter question"
                                                                    className="form-control"
                                                                />
                                                                {errors.questions?.[qIndex]?.question_text &&
                                                                    touched.questions?.[qIndex]?.question_text && (
                                                                        <div className="text-danger">
                                                                            {errors.questions[qIndex].question_text}
                                                                        </div>
                                                                    )}
                                                            </div>

                                                            {/* Options */}
                                                            <FieldArray name={`questions.${qIndex}.options`}>
                                                                {() => (
                                                                    <div>
                                                                        {question.options.map((option, oIndex) => (
                                                                            <div key={oIndex} className="mb-3">
                                                                                <label className="form-label">
                                                                                    Option {oIndex + 1}
                                                                                </label>
                                                                                <Field
                                                                                    name={`questions.${qIndex}.options.${oIndex}`}
                                                                                    placeholder={`Option ${oIndex + 1}`}
                                                                                    className="form-control"
                                                                                />
                                                                                {errors.questions?.[qIndex]?.options?.[oIndex] &&
                                                                                    touched.questions?.[qIndex]?.options?.[oIndex] && (
                                                                                        <div className="text-danger">
                                                                                            {errors.questions[qIndex].options[oIndex]}
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </FieldArray>

                                                            {/* Correct Answer */}
                                                            <div className="mb-3">
                                                                <label className="form-label">Correct Answer</label>
                                                                <Field
                                                                    as="select"
                                                                    name={`questions.${qIndex}.answers`}
                                                                    className="form-select"
                                                                >
                                                                    <option value="">Select Correct Answer</option>
                                                                    {question.options.map((option, oIndex) => (
                                                                        <option key={oIndex} value={option}>
                                                                            {option || `Option ${oIndex + 1}`}
                                                                        </option>
                                                                    ))}
                                                                </Field>
                                                                {errors.questions?.[qIndex]?.answers &&
                                                                    touched.questions?.[qIndex]?.answers && (
                                                                        <div className="text-danger">
                                                                            {errors.questions[qIndex].answers}
                                                                        </div>
                                                                    )}
                                                            </div>

                                                            {/* Remove Question Button */}
                                                            {/* <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => remove(qIndex)}
                                                            >
                                                                Remove Question
                                                            </button> */}
                                                        </div>
                                                    </Col>
                                                ))}

                                                {/* Add Question Button */}
                                                {/* <button
                                                    type="button"
                                                    className="btn btn-primary mb-3"
                                                    onClick={() =>
                                                        push({
                                                            question_text: '',
                                                            options: ['', '', '', ''],
                                                            answers: '',
                                                        })
                                                    }
                                                >
                                                    Add Question
                                                </button> */}
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{
                                                        position: "fixed",
                                                        bottom: "20px",
                                                        right: "20px",
                                                        width: "200px",
                                                        height: "40x",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "18px",
                                                    }}
                                                    onClick={() =>
                                                        push({
                                                            question_text: '',
                                                            options: ['', '', '', ''],
                                                            answers: '',
                                                        })
                                                    }
                                                >
                                                    Add Another Question
                                                </button>

                                            </>
                                        )}
                                    </FieldArray>
                                </Row>
                                {/* Submit Button */}
                                <button type="submit" className="btn btn-success">
                                    Submit
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default AddQuizPage;
