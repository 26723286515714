import axios from "axios";
import { BASE_API_URL } from "./config";
import secureLocalStorage from "react-secure-storage";

export const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    let token = secureLocalStorage.getItem("admin-token");
    if (!token) {
        token = secureLocalStorage.getItem("token");
    }
    if (token) {
      // Set the Authorization header if token exists
      config.headers['Authorization'] = `${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);
