import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import adminStore from '../../store/AdminStore';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import AdminHeader from '../../component/AdminHeader';
import AdminSidebar from '../../component/AdminSidebar';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
    question_id: Yup.string().required('Question ID is required'),
    question_text: Yup.string().required('Question text is required'),
    answers: Yup.array()
        .of(Yup.string().required('Option is required'))
        .length(4, 'There must be exactly four answers'),
    answer: Yup.string().required('Select a correct answer'),
});

const EditQuiz = () => {
    const location = useLocation();
    const topicData = location.state;

    // Initialize values from the received topicData
    const initialValues = {
        question_id: topicData?.question_id || '',
        question_text: topicData?.question_text || '',
        answers: topicData?.answers?.map((a) => a.answer_text) || ['', '', '', ''],
        answer: topicData?.answers?.find((a) => a.is_correct)?.answer_text || '',
    };

    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        try {
            await adminStore.updateQuiz(values,navigationCallBack);
        } catch (error) {
            console.error('Error updating quiz:', error);
        }
    };

    const navigationCallBack = () =>{
        navigate("/admin/quiz")
    }

    return (
        <div>
            <AdminHeader />
            <div style={{ display: 'flex', flexDirection: 'row', height: 'fit-content' , backgroundColor:"white" }}>
                <AdminSidebar />
                <div className="container mt-4">
                    <h1 className="text-left mb-4">Edit Quiz</h1>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, errors, touched }) => (
                            <Form>
                                {/* Question ID */}
                                <div className="mb-4">
                                    <label className="form-label">Question ID</label>
                                    <Field
                                        name="question_id"
                                        placeholder="Enter Question ID"
                                        className="form-control"
                                    />
                                    {errors.question_id && touched.question_id && (
                                        <div className="text-danger">{errors.question_id}</div>
                                    )}
                                </div>

                                {/* Question Text */}
                                <div className="mb-4">
                                    <label className="form-label">Question Text</label>
                                    <Field
                                        name="question_text"
                                        placeholder="Enter question"
                                        className="form-control"
                                    />
                                    {errors.question_text && touched.question_text && (
                                        <div className="text-danger">{errors.question_text}</div>
                                    )}
                                </div>

                                {/* Answers */}
                                {values.answers.map((option, index) => (
                                    <div key={index} className="mb-3">
                                        <label className="form-label">Option {index + 1}</label>
                                        <Field
                                            name={`answers[${index}]`}
                                            placeholder={`Option ${index + 1}`}
                                            className="form-control"
                                        />
                                        {errors.answers?.[index] &&
                                            touched.answers?.[index] && (
                                                <div className="text-danger">
                                                    {errors.answers[index]}
                                                </div>
                                            )}
                                    </div>
                                ))}

                                {/* Correct Answer */}
                                <div className="mb-4">
                                    <label className="form-label">Correct Answer</label>
                                    <Field as="select" name="answer" className="form-select">
                                        <option value="">Select Correct Answer</option>
                                        {values.answers.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option || `Option ${index + 1}`}
                                            </option>
                                        ))}
                                    </Field>
                                    {errors.answer && touched.answer && (
                                        <div className="text-danger">{errors.answer}</div>
                                    )}
                                </div>

                                <button type="submit" className="btn btn-success mt-4">
                                    Update Quiz
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default EditQuiz;
