import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Card, Button } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import adminStore from "../../store/AdminStore";
import AdminSidebar from "../../component/AdminSidebar";
import AdminHeader from "../../component/AdminHeader";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";


const EditTopic = observer(() => {
  const [initialValues, setInitialValues] = useState({
    video_title: "",
    video_description: "",
    video_url: "",
    video_thumbnail: null,
    video_id: "",
  });

  const navigate = useNavigate();
  const location = useLocation();
  const topicData = location.state;

  useEffect(() => {
    if (topicData) {
      setInitialValues({
        video_title: topicData.video_title || "",
        video_description: topicData.video_description || "",
        video_url: topicData.video_url || "",
        video_thumbnail: null, 
        video_id: topicData.video_id || "",
      });
    }
  }, [topicData]);

  const handleSaveVideo = async (values) => {
    const formData = new FormData();

    // Append video data
    formData.append("video_id", values.video_id);
    formData.append("video_title", values.video_title);
    formData.append("video_description", values.video_description);
    formData.append("video_url", values.video_url);

    console.log(topicData,"anik");
    
    if (values.video_thumbnail) {
      formData.append("video_thumbnail", values.video_thumbnail);
    } else {
      formData.append("thumbnail", topicData?.thumbnail); 
    }

    try {
      await adminStore.updateVideo(formData); 
        navigate("/admin/show-topic"); 

    } catch (error) {
      console.error("Error updating video:", error);
    }
  };

  const validationSchema = Yup.object().shape({
    video_title: Yup.string().required("Video title is required"),
    video_description: Yup.string().required("Video description is required"),
    video_url: Yup.string()
      .url("Enter a valid URL")
      .required("Video URL is required"),
  });
  

  return (
    <div>
      <AdminHeader />
      <div style={{ display: "flex", flexDirection: "row", height: "92.2vh",backgroundColor:"white" }}>
        <AdminSidebar />
        <div style={{ marginLeft: "20px", padding: "20px", width: "100%" }}>
          <h2>Edit Video</h2>
          <Card className="my-3">
            <Card.Body>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSaveVideo}
                validationSchema={validationSchema}
                enableReinitialize={true} // Ensures Formik reinitializes when initialValues change
              >
                {({ setFieldValue }) => (
                  <Form>
                    <div className="mb-3">
                      <label>Video Title</label>
                      <Field
                        type="text"
                        name="video_title"
                        placeholder="Enter video title"
                        className="form-control"
                      />
                      <ErrorMessage name="video_title" component="div" className="text-danger" />
                    </div>

                    <div className="mb-3">
                      <label>Video Description</label>
                      <Field
                        as="textarea"
                        rows={3}
                        name="video_description"
                        placeholder="Enter description"
                        className="form-control"
                      />
                      <ErrorMessage name="video_description" component="div" className="text-danger" />
                    </div>

                    <div className="mb-3">
                      <label>Video URL</label>
                      <Field
                        type="text"
                        name="video_url"
                        placeholder="Enter video URL"
                        className="form-control"
                      />
                      <ErrorMessage name="video_url" component="div" className="text-danger" />
                    </div>

                    <div className="mb-3">
                      <label>Video Thumbnail</label>
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        onChange={(e) =>
                          setFieldValue("video_thumbnail", e.target.files[0])
                        }
                      />
                    </div>

                    <Button variant="success" type="submit">
                      Save Video
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
});

export default EditTopic;
