import React, { useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import AdminHeader from "../../component/AdminHeader";
import AdminSidebar from "../../component/AdminSidebar";
import ReactDatatable from "@mkikets/react-datatable";
import adminStore from "../../store/AdminStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import Loader from "../../component/Loader";
import Select from "react-select";

const Results = observer(() => {

  const [userQuizScore ,setUserQuizScore] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [courseData, setCourseData] = useState([]);

  useEffect(()=>{
 setCourseData(toJS(adminStore?.data?.courses))
  },[adminStore?.data?.courses])
  
  useEffect(()=>{
    adminStore.getUserQuizScore();
    adminStore?.getCourses();
  }, []);
  
  const handleChange = (selected) => {
    setSelectedOption(selected);

    if (selected && selected.value) {
        const filteredVideos = toJS(adminStore?.data?.all_quiz_result).map((item) => item.quiz_result).filter(
            (video) => video.course_id === selected.value
        );
        setUserQuizScore(filteredVideos);
    } else {
      setUserQuizScore(toJS(adminStore?.data?.all_quiz_result).map((item) => item.quiz_result));
    }
};

  
  useEffect(()=>{
    if(adminStore?.data?.all_quiz_result){
      setUserQuizScore(toJS(adminStore?.data?.all_quiz_result).map((item) => item.quiz_result));
    }
  },[adminStore?.data?.all_quiz_result]);
     
  
  const options = Array.isArray(courseData)
  ? courseData.map((course) => ({
    value: course?.course_id,
    label: course?.course_name,
  }))
  : [];


  const columns = [
    {
      key: "s_no",
      text: "S No.",
      align: "left",
      sortable: true,
      cell: (record, index) => (
        <span>{index + 1}</span>
      ),
    },
    {
      key: "user_name",
      text: "User Name",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      align: "left",
      sortable: true,
    },
    {
      key: "wallet_address",
      text: "Wallet Address",
      align: "left",
      sortable: true,
    },
    {
      key: "course_name",
      text: "Project name",
      align: "left",
      sortable: true,
    },
    {
      key: "quiz_name",
      text: "Quiz Name",
      align: "left",
      sortable: true,
    },
    {
      key: "score",
      text: "Total Score",
      align: "left",
      sortable: true,
    },
    // {
    //   key: "percentage",
    //   text: "Percentage",
    //   align: "left",
    //   sortable: true,
    // }, 
    // {
    //   key: "action",
    //   text: "Action",
    //   align: "left",
    //   sortable: true,
    // },
    
    
  ];
  // Configuration for ReactDatatable
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Projects",
    no_data_text: "No Projects found!",
    button: {
      excel: true,
    },
  };
  return (
  
    <div>
    <AdminHeader />
    <div style={{ display: "flex", flexDirection: "row", height: "92.2vh", backgroundColor:"white" }}>
        <AdminSidebar />
        <div className="flex-grow-1" style={{
    flex: 1,
    overflow: "auto",
    padding: "30px",
  }}>
  <div className="container my-2">
  <div className='d-flex justify-content-between '>
            <h2 className=" mb-4">Results</h2>
            <div>
              <p className="text-center fs-5 ">Select Project</p>
            <Select
                options={options}
                value={selectedOption}
                onChange={handleChange}
                placeholder="Select Project"
                styles={{
                  container: (base) => ({ ...base, width: "200px", marginTop: "10px" }),
                }}
                isMulti
              />
            </div>
           
            </div>
    <div className="table-responsive mt-2">
      {/* Check if data is passed and columns are properly defined */}
      <ReactDatatable config={config} records={userQuizScore} columns={columns} />
    </div>
  </div>
</div>

    </div>
</div>
  );
});

export default Results;
// dfgdfgf