import { observer } from "mobx-react";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import adminStore from "../store/AdminStore";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../component/Loader";

const Login = observer(() => {
  const [isLoginActive, setIsLoginActive] = useState(true);
  const [isForgotPasswordActive, setIsForgotPasswordActive] = useState(false);
  const navigate = useNavigate();
  const loginFormRef = useRef(null);
  const signupFormRef = useRef(null);
  const forgotPasswordFormRef = useRef(null);
  const handleLoginSubmit = async (values) => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      const ipAddress = response.data.ip;
      const creds = {
        email: values.loginEmail,
        password: values.loginPassword,
        ip_address: ipAddress,
      };
      await adminStore.login(creds, navigationCallBack);
    } catch (error) {
      console.error("Failed to fetch IP address or log in:", error);
      toast.error("Unable to log in. Please try again.");
    }
  };

  const navigationCallBack = (user) => {
    toast.success("Logged In Successfully");
    if (user.role === 1) {
      navigate("/admin/dashboard", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  };

  const handleSignUpSubmit = async (values) => {
    try {

      // Fetch the user's IP address
      const response = await axios.get("https://api.ipify.org?format=json");
      const ipAddress = response.data.ip;

      // Create the credentials object with IP address
      const creds = {
        email: values.email,
        password: values.password,
        first_name: values.first_name,
        last_name: values.last_name,
        ip_address: ipAddress, // Add IP address here
      };

      // Call the signup function with creds and navigation callback
      await adminStore.signUp(creds, navigationCallBackSignUp);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }

  };
  const navigationCallBackSignUp = (param) => {
    toast.success("OTP sent successfully");
    navigate(`/otp-verification?email=${param}`)


  };
  const navigationCallBackForgotPassword = (param) => {
    toast.success("Password reset OTP sent successfully");
    navigate(`/reset-password?email=${param}`)
  };

  const handleForgotPasswordSubmit = async (values) => {
    try {

      const creds = {
        email: values?.forgotEmail,
      };
      const response = await adminStore?.forgotPassword(
        creds,
        navigationCallBackForgotPassword
      );
    } catch (error) {
      console.error("Password reset OTP sent failed:", error);
      toast.error("Password reset OTP sent failed.");
    }

  };

  const loginValidationSchema = Yup.object().shape({
    loginEmail: Yup.string().email("Invalid email").required("Required"),
    loginPassword: Yup.string().required("Required"),
  });

  const signupValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[A-Za-z]+$/, "must only contain letters")
      .required("Required"),
    last_name: Yup.string()
      .matches(/^[A-Za-z]+$/, "must only contain letters")
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      )
      .required("Required"),
    password: Yup.string().required("Required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });

  const forgotPasswordValidationSchema = Yup.object().shape({
    forgotEmail: Yup.string().email("Invalid email").required("Required"),
  });

  return (

    <div className="port-view">
      <div className="wrapper">
        {/* <div className="title-text">
          {isForgotPasswordActive ? (
            <div className="title">Forgot Password</div>
          ) : (
            <>
              <div className={`title login ${isLoginActive ? "active" : ""}`}>
                {isLoginActive ? "Login Form" : "Signup Form"}
              </div>
              <div className={`title signup ${!isLoginActive ? "active" : ""}`}>
                {!isLoginActive ? "Signup Form" : "Login Form"}
              </div>
            </>
          )}
        </div> */}
        <div className="form-container">
          {!isForgotPasswordActive && (
            <>
              <div className="slide-controls">
                <input
                  type="radio"
                  name="slide"
                  id="login"
                  checked={isLoginActive}
                  onChange={() => {
                    setIsLoginActive(true);
                    signupFormRef.current?.resetForm();
                  }}
                />
                <input
                  type="radio"
                  name="slide"
                  id="signup"
                  checked={!isLoginActive}
                  onChange={() => {
                    setIsLoginActive(false);
                    loginFormRef.current?.resetForm();
                  }}
                />
                <label
                  htmlFor="login"
                  className="slide login"
                  onClick={() => {
                    setIsLoginActive(true);
                    signupFormRef.current?.resetForm();
                  }}
                >
                  Login
                </label>
                <label
                  htmlFor="signup"
                  className="slide signup"
                  onClick={() => {
                    setIsLoginActive(false);
                    loginFormRef.current?.resetForm();
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  Signup
                </label>
                <div className="slide-tab" />
              </div>
            </>
          )}

          <div className="form-inner">
            {isForgotPasswordActive ? (
              <Formik
                initialValues={{ forgotEmail: "" }}
                validationSchema={forgotPasswordValidationSchema}
                onSubmit={handleForgotPasswordSubmit}
                innerRef={forgotPasswordFormRef}
                autoComplete="off"
                enableReinitialize
              >
                {() => (
                  <Form className="forgot-password">
                    <div className="field">
                      <Field
                        type="text"
                        name="forgotEmail"
                        placeholder="Email Address"
                        autoComplete="email"
                      />
                      <ErrorMessage
                        name="forgotEmail"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>

                    <div className="field">
                      <input type="submit" value="Reset Password" />
                    </div>
                    <div className="signup-link pass-link">
                      Remembered your password?
                      <br />
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsForgotPasswordActive(false);
                          loginFormRef.current?.resetForm();
                        }}
                      >
                        Login Now
                      </a>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : isLoginActive ? (
              <Formik
                initialValues={{ loginEmail: "", loginPassword: "" }}
                validationSchema={loginValidationSchema}
                onSubmit={handleLoginSubmit}
                innerRef={loginFormRef}
                autoComplete="off"
                enableReinitialize
              >
                {() => (
                  <Form className="login">
                    <div className="field">
                      <Field
                        type="text"
                        name="loginEmail"
                        id="loginEmail"
                        placeholder="Email Address"
                        autoComplete="username"
                      />
                      <ErrorMessage
                        name="loginEmail"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div className="field">
                      <Field
                        type="password"
                        name="loginPassword"
                        id="loginPassword"
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                      <ErrorMessage
                        name="loginPassword"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div className="pass-link">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsForgotPasswordActive(true);
                        }}
                      >
                        Forgot Password
                      </a>
                    </div>
                    <div className="field">
                      <input type="submit" value="Login" />
                    </div>
                    <div className="signup-link pass-link">
                      Not a member?
                      <br />
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsLoginActive(false);
                          loginFormRef.current?.resetForm();
                        }}

                      >
                        Signup Now
                      </a>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  contact: "",
                  email: "",
                  password: "",
                  ip_address: "",
                }}
                validationSchema={signupValidationSchema}
                onSubmit={handleSignUpSubmit}
                innerRef={signupFormRef}
                autoComplete="off"
                enableReinitialize
              >
                {() => (
                  <Form className="signup">
                    <div className="field">
                      <Field
                        type="text"
                        name="first_name"
                        placeholder="Enter First Name"
                        autoComplete="name"
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div className="field">
                      <Field
                        type="text"
                        name="last_name"
                        placeholder="Enter Last Name"
                        autoComplete="name"
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div className="field">
                      <Field
                        type="text"
                        name="email"
                        placeholder="Email Address"
                        autoComplete="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div className="field">
                      <Field
                        type="password"
                        name="password"
                        placeholder="Password"
                        autoComplete="new-password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div className="field">
                      <Field
                        type="password"
                        name="confirm_password"
                        placeholder="Confirm Password"
                        autoComplete="new-password"
                      />
                      <ErrorMessage
                        name="confirm_password"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div className="field">
                      <input type="submit" value="Submit" />
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
      {adminStore.loading && <Loader />}
    </div>
  );
});

export default Login;
