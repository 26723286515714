import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import adminStore from "../store/AdminStore";

const ResetPassword = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get("email");

  const handleResetPasswordSubmit = async (values) => {
    try {
      const creds = {
        email:email,
        otp: values?.otp,
        password: values?.newPassword,
      };
      await adminStore.resetPassword(creds, navigationCallBack);
      navigate("/login"); // Navigate back to login page after success
    } catch (error) {
      console.error("Failed to reset password:", error);
      toast.error("Failed to reset password. Please try again.");
    }
  };

  const navigationCallBack = () => {
    toast.success("Password Reset Successfully");
    navigate("/login");
  };

  const resetPasswordValidationSchema = Yup.object().shape({
    otp: Yup.string()
      .required("Required")
      .matches(/^\d{6}$/, "OTP must be a 6-digit number"),
    newPassword: Yup.string()
      .required("Required")
      .min(6, "Password must be at least 6 characters long"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Required"),
  });

  return (
    <div className="port-view">
      <div className="wrapper">
        <div className="form-container">
          <div className="form-inner">
            <Formik
              initialValues={{
                otp: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={resetPasswordValidationSchema}
              onSubmit={handleResetPasswordSubmit}
              autoComplete="off"
              enableReinitialize
            >
              {() => (
                <Form className="reset-password">
                  <div className="field">
                    <Field
                      type="text"
                      name="otp"
                      placeholder="Enter OTP"
                      autoComplete="one-time-code"
                    />
                    <ErrorMessage
                      name="otp"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                  <div className="field">
                    <Field
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                      autoComplete="new-password"
                    />
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                  <div className="field">
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      autoComplete="new-password"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                  <div className="field">
                    <input type="submit" value="Submit" />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
