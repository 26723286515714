import { configure, makeAutoObservable, runInAction } from "mobx";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { axiosInstance } from "../api/axiosInstance";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";

class AdminStore {
  loading = false;
  loadingApply = false;
  data = {
    quiz_result: [],
    courses: [],
    progress: [],
    quizes: [],
    // quizes1: [],
    all_quiz_result: [],
    user_quiz_result: [],
    users: [],
    dashboardCount: [],
    videos: [],
    topics: [],
    allQuiz: [],
    all_Quiz: []
  };
  errors = {};

  constructor() {
    makeAutoObservable(this);
    configure({
      useProxies: "never",
    });
  }

  toggleLoading(toggle) {
    this.loading = toggle;
  }

  toggleLoadingApply(toggle) {
    this.loadingApply = toggle;
  }

  //=========================== User SignUp/Login Apis =============================

  async login(creds, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post("/sign-in", creds);
      const token = response?.data?.user?.authtoken;
      const user = response?.data?.user;

      if (token && user) {
        this.setTokenAndUserData(user, token);
        navigationCallBack(user);
      } else {
        throw new Error("Login failed. Invalid credentials.");
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";

      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }

  async signUp(creds, navigationCallBackSignUp) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post("/sign-up", creds);
      const status = response?.data?.status;

      if (status) {
        navigationCallBackSignUp(response?.data?.email);
      } else {
        throw new Error("Signup failed. Please check your details.");
      }
    } catch (err) {

      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async verifyOtp(creds, navigationCallBackVerify) {
    this.toggleLoading(true);

    try {
      const response = await axiosInstance.put("/verify", creds);
      const status = response?.data?.status;

      if (status) {
        navigationCallBackVerify();
      } else {
        throw new Error("Verification failed. Please check your details.");
      }
    } catch (err) {
      console.log(err?.response?.data?.message);

      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async resendOtp(creds, navigationCallBackResendOtp) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put("/resend-otp", creds);
      const status = response?.data?.status;

      if (status) {
        navigationCallBackResendOtp();
      } else {
        throw new Error("Resend failed");
      }
    } catch (err) {
      console.log(err?.response?.data?.message);

      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async forgotPassword(creds, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post("/forgot-password", creds);

      const status = response?.data?.message;

      if (status) {
        navigationCallBack(creds?.email);
      } else {
        throw new Error("Signup failed. Please check your details.");
      }
    } catch (err) {

      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async resetPassword(creds, navigationCallBackResetPassword) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put("/reset-password", creds);
      const status = response?.data?.message;
      if (status) {
        navigationCallBackResetPassword();
      } else {
        throw new Error("Verification failed. Please check your details.");
      }
    } catch (err) {
      console.log(err?.response?.data?.message);

      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }

  async getProfile(creds) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/user", creds);
      const token = response?.data?.token;
      const user = response?.data?.user;

      if (token && user) {
        this.setTokenAndUserData(user, token);
      } else {
        throw new Error("Signup failed. Please check your details.");
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }

  setTokenAndUserData(userData, token) {
    try {
      secureLocalStorage.setItem("user", JSON.stringify(userData));
      if (userData?.role == 0) {
        secureLocalStorage.setItem("token", token);
      } else {
        secureLocalStorage.setItem("admin-token", token);
      }
    } catch (storageError) {
      toast.error("Failed to save user data. Please try again.");
    }
  }

  //=========================== Landing Page Apis =============================

  async getCourses() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/get-course");
      if (response) {
        runInAction(() => {
          this.data.courses = response?.data?.courses;
        });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err);
    } finally {
      this.toggleLoading(false);
    }
  }
  async getActiveCourses() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/get-active-course");
      if (response) {
        runInAction(() => {
          this.data.courses = response?.data?.courses;
        });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err);
    } finally {
      this.toggleLoading(false);
    }
  }


  async updateCourse(formData) {
    this.toggleLoading(true);

    try {
      const response = await axiosInstance.put('/update-course', formData);
      console.log(response, "response");


      if (response) {
        toast.success(response?.data?.message || "Project updated successfully.");
        return true;
      } else {
        toast.error(response?.data?.message || "Failed to update the course.");
        return false;
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        toast.error("Unauthorized access. Please log in again.");
      } else {
        toast.error(err?.response?.data?.message || "An error occurred while updating the course.");
      }
      return false;
    } finally {
      this.toggleLoading(false);
    }
  }
  // =====================edit user=============

  async updateUser(formData) {
    this.toggleLoading(true);

    try {
      const response = await axiosInstance.put('/update-user', formData);

      if (response) {
        toast.success("User updated successfully!");
        return (true)
      } else {
        toast.error(response?.data?.message || "Failed to update user.");
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        toast.error("Unauthorized access. Please log in again.");
        // Optional: You can trigger a logout or redirect to the login page
        // window.location.reload();
      } else {
        toast.error(err?.response?.data?.message || "An error occurred while updating the user.");
      }
    } finally {
      this.toggleLoading(false); // Hide loader
    }
  }

  async updateQuiz(formData,navigationCallBack) {
    this.toggleLoading(true);

    try {
      const response = await axiosInstance.put('/update-question', formData);

      if (response) {
        toast.success("quiz updated successfully!");
        navigationCallBack();
      } else {
        toast.error(response?.data?.message || "Failed to update the course.");
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        toast.error("Unauthorized access. Please log in again.");
        // Optional: You can trigger a logout or redirect to the login page
        // window.location.reload();
      } else {
        toast.error(err?.response?.data?.message || "An error occurred while updating the course.");
      }
    } finally {
      this.toggleLoading(false); // Hide loader
    }
  }
  async updateQuizSec(formData) {
    this.toggleLoading(true);

    try {
      const response = await axiosInstance.put('/update-quiz', formData);

      if (response) {
        toast.success("quiz updated successfully!");

      } else {
        toast.error(response?.data?.message || "Failed to update the course.");
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        toast.error("Unauthorized access. Please log in again.");
        // Optional: You can trigger a logout or redirect to the login page
        // window.location.reload();
      } else {
        toast.error(err?.response?.data?.message || "An error occurred while updating the course.");
      }
    } finally {
      this.toggleLoading(false); // Hide loader
    }
  }

  async updateVideo(formData) {
    this.toggleLoading(true);

    try {
      const response = await axiosInstance.put('/update-video', formData);

      if (response) {
        toast.success(response?.data?.message);

      } else {
        toast.error(response?.data?.message || "Failed to update the topic.");
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        toast.error("Unauthorized access. Please log in again.");
        // Optional: You can trigger a logout or redirect to the login page
        // window.location.reload();
      } else {
        toast.error(err?.response?.data?.message || "An error occurred while updating the topic.");
      }
    } finally {
      this.toggleLoading(false); // Hide loader
    }
  }


  async addCourse(param) {
    this.toggleLoading(true);

    try {
      const response = await axiosInstance.post('create-course', param);


      if (response) {
        toast.success(response?.data?.message || "Project created successfully.");
        return response.data;
      } else {
        toast.error(response?.data?.message || "Failed to create the course.");
        return false; // Explicit failure return
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";

      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
      return false;
    } finally {
      this.toggleLoading(false);
    }
  }


  async addQuiz(param) {
    this.toggleLoading(true);  // Show loading indicator

    try {
      const response = await axiosInstance.post('create-quiz', param);

      // Check if the response is successful (adjust based on the API response)
      if (response) {
        toast.success(response?.data?.message || "Quiz added successfully.");
        return response?.data;  // Return data for further processing (optional)
      } else {
        // If the API response indicates failure
        toast.error(response?.data?.message || "Failed to add quiz.");
        return { success: false, message: response?.data?.message || "Failed to add quiz." };
      }
    } catch (err) {
      // Error handling for network or server issues
      const errorMsg = err?.response?.data?.message || "Something went wrong!";

      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }

      // Return a failure response
      return { success: false, message: errorMsg };
    } finally {
      this.toggleLoading(false);  // Hide loading indicator
    }
  }


  async addQuizQuestions(param) {
    this.toggleLoading(true);  // Show loading indicator

    try {
      const response = await axiosInstance.post('create-questions', param);

      // Check if the response is successful (adjust based on the API response)
      if (response) {
        toast.success(response?.data?.message || "question added successfully.");
        return response?.data;  // Return data for further processing (optional)
      } else {
        // If the API response indicates failure
        toast.error(response?.data?.message || "Failed to add question.");
        return { success: false, message: response?.data?.message || "Failed to add question." };
      }
    } catch (err) {
      // Error handling for network or server issues
      const errorMsg = err?.response?.data?.message || "Something went wrong!";

      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }

      // Return a failure response
      return { success: false, message: errorMsg };
    } finally {
      this.toggleLoading(false);  // Hide loading indicator
    }
  }


  //=========================== Youtube Page Apis =============================

  async getVideoById(id) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get(`/get-video-by-course/${id}`);
      if (response) {
        runInAction(() => {
          this.data.videos = response?.data?.results;
        });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }

  async getWatchDuration(creds) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put("get-progress", creds);
      if (response) {
        runInAction(() => {
          this.data.progress = response?.data?.data[0];
        });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async getCompletedProgress(param) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post("get-completed-progress", param);
      if (response) {
        return response?.data?.data
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }

  async updateWatchDuration(duration) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put("update-progress", duration);
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }



  async updateActive(data) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put("/course-visibility", data);
      return response.data; // Return response data if needed
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }

  //=========================== Quiz Page Apis =============================

  async getQuiz(id) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get(`/get-quiz-user/${id}`);
      if (response) {
        // runInAction(() => {
        this.data.quizes = response?.data;
        // });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      console.log("error", err);

      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }

  async getAllQuizById(id) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get(`/get-all-quiz-by-course/${id}`);
      if (response) {
        // runInAction(() => {
        this.data.allQuiz = response?.data?.quizzes;
        // });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      console.log("error", err);

      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async getAllQuiz(id) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get(`/get-all-quiz`);
      if (response) {
        // runInAction(() => {
        this.data.all_Quiz = response?.data?.quizzes;
        // });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      console.log("error", err);

      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async getQuizAdmin(id) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get(`/get-quiz/${id}`);
      if (response) {
        // runInAction(() => {
        this.data.quizes = response?.data?.quiz;
        // });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }



  async startQuiz(param) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post('start-quiz', param)
      if (response) {
        return response?.data?.attempt_id;
      }
    }
    catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    }
    finally {
      this.toggleLoading(false);
    }
  }

  async addVideo(param) {
    this.toggleLoading(true);

    try {
      const response = await axiosInstance.post('create-video', param);

      if (response) {
        toast.success(response?.data?.message || "Video added successfully.");
        return response?.data; // Return response for further processing
      } else {
        toast.error(response?.data?.message || "Failed to add the video.");
        return false; // Explicit failure return
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";

      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
      return false; // Explicit failure return
    } finally {
      this.toggleLoading(false); // Ensure the loader is hidden
    }
  }


  async submitQuizAnswer(param) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.post("/submit-answer", param);
      if (response) {
        return response?.data?.result;
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }


  async getQuizResult() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/quiz-results");
      if (response) {
        runInAction(() => {
          this.data.quiz_result = response?.data?.quiz_result;
        });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }



  // ==================Result api===================
  async getUserQuizScore() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/get-quiz-score");
      if (response) {
        runInAction(() => {
          this.data.all_quiz_result = response?.data?.results;
        });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async getUserQuizScoreById(id) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get(`/get-user-quiz-score/${id}`);
      if (response) {
        runInAction(() => {
          this.data.user_quiz_result = response?.data?.results?.quizzes;
        });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }




  async deleteTopic(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put(`delete-video/${id}`);
      if (response) {
        toast.success(response.data.message)
        navigationCallBack();
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async deleteQuizSec(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put(`delete-quiz/${id}`);
      if (response) {
        toast.success(response.data.message)
        navigationCallBack();
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async deleteUser(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put(`delete-user/${id}`);
      if (response) {
        toast.success(response.data.message)
        navigationCallBack();
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async deleteQuiz(id, navigationCallBack) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put(`delete-question/${id}`);
      if (response) {
        toast.success(response.data.message)
        navigationCallBack();
      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  async deleteCourse(id, navigationCallBackDelete) {
    // setAuthToken(global.config.token)
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put(`delete-course/${id}`);
      if (response) {
        navigationCallBackDelete()
        toast.success(response.data.message)

      }
    } catch (err) {
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }



  async submitWalletAddress(param, navigationCallBack) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.put("/add-wallet", param);
      if (response) {
        navigationCallBack(response.data.message)
        return response?.data;
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err);
    } finally {
      this.toggleLoading(false);
    }
  }

  async getUsers() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/get-users");
      if (response) {
        runInAction(() => {
          this.data.users = response?.data?.data;
        });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      console.log(err);
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }

  async getvideos() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/get-videos");
      if (response) {
        runInAction(() => {
          this.data.topics = response?.data?.results;
        });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      console.log(err);
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }

  async getUserById(id) {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get(`/get-user/${id}`);
      if (response) {
        runInAction(() => {
          this.data.users = response?.data?.data[0];
        });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      console.log(err);
      const errorMsg = err?.response?.data?.message || "Something went wrong!";
      if (err?.response?.status !== 500) {
        toast.error(errorMsg);
      } else {
        toast.error("Server error. Please try again later.");
      }
    } finally {
      this.toggleLoading(false);
    }
  }
  // ================Result Count===================

  async getCount() {
    this.toggleLoading(true);
    try {
      const response = await axiosInstance.get("/get-dashboard-status");
      if (response) {
        runInAction(() => {
          this.data.dashboardCount = response?.data;
        });
      } else {
        // toast.error("Something went to wrong!");
      }
    } catch (err) {
      if (err?.request?.status === 401) {
        // window.location.reload()
      }
      console.log(err);
    } finally {
      this.toggleLoading(false);
    }
  }
}

const adminStore = new AdminStore();
export default adminStore;
