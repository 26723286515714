import React from 'react';
import loaderImage from '../assets/images/1484.gif'; // Adjust path based on your file location

const Loader = () => (
  <div className="spinner">
    <img src={loaderImage} width={100} alt="Loading..." />
    <center className='ps-3'>Loading...</center>
  </div>
);

export default Loader;